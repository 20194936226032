import { SEARCH_SHOPEE } from './types';
const querystring = require('querystring');

const MENU_URL = 'http://13.230.176.68:8084/api/shopee_search?';

const searchShopee = (searchParams) => (dispatch) => {
    let searchUrl = MENU_URL;
    searchUrl += searchParams.hasOwnProperty('by')
        ? `by=${searchParams.by}`
        : 'by=relevancy';
    searchUrl += searchParams.hasOwnProperty('newest')
        ? `&newest=${searchParams.newest}`
        : '&newest=0';
    searchUrl += searchParams.hasOwnProperty('order')
        ? `&order=${searchParams.order}`
        : '&order=asc';
    searchUrl += searchParams.hasOwnProperty('page_type')
        ? `&page_type=${searchParams.page_type}`
        : '&page_type=search';
    searchUrl += searchParams.hasOwnProperty('limit')
        ? `&limit=${searchParams.limit}`
        : '&limit=50';
    searchUrl += searchParams.hasOwnProperty('rating_filter')
        ? `&rating_filter=${searchParams.rating_filter}`
        : '&rating_filter=4';
    searchUrl += searchParams.hasOwnProperty('condition')
        ? `&condition=${searchParams.condition}`
        : '';
    searchUrl += searchParams.hasOwnProperty('price_min')
        ? `&price_min=${searchParams.price_min}`
        : '';
    searchUrl += searchParams.hasOwnProperty('price_max')
        ? `&price_max=${searchParams.price_max}`
        : '';

    let keyword = searchParams.hasOwnProperty('keyword')
        ? `${searchParams.keyword}`
        : 'switch';
    let keywordEncoded = querystring.stringify({ keyword: keyword });
    searchUrl += `&${keywordEncoded}`;

    console.log(searchUrl);

    fetch(searchUrl, {
        method: 'GET',
        mode: 'cors',
    })
        .then((res) => res.json())
        .then((allStores) => {
            console.log(JSON.stringify(allStores));
            dispatch({
                type: SEARCH_SHOPEE,
                payload: allStores,
            });
        });
};

export { searchShopee };
