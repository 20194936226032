/* jshint esversion: 8 */
import searchList from './components/searchList';
import billboard from './components/billboard';
import memberList from './components/memberList';
import ArticlesList from './components/ArticlesList';
import login from './components/login';
import register from './components/register';
import dashboard from './views/dashboard';
import MemberArticles from './components/MemberArticles';
import TrackingGoods from './components/TrackingGoods';
import MyshopperUser from './components/MyshopperUser';
import MyshopperNotifyRecord from './components/MyshopperNotifyRecord';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import MemberView from './views/MemberView';
import MenuSettingRoute from 'views/menu/MenuSettingRoute';
import UserSettingRoute from 'views/accounts/UserSettingRoute';
import { authRoles } from 'auth/authRoles';

const settings = {
    show: false
};
const loginsettings = {
    show: false
};
const routes = [
    ...dashboardRoutes,
    ...MenuSettingRoute,
    ...UserSettingRoute,
    {
        path: '/',
        exact: true,
        component: login,
        settings,
    },
    {
        path: '/searchList',
        exact: true,
        component: searchList,
    },
    {
        path: '/billboard',
        exact: true,
        component: billboard,
    },
    {
        path: '/memberList',
        exact: true,
        component: memberList,
    },
    {
        path: '/articlesList',
        exact: true,
        component: ArticlesList,
    },
    {
        path: '/articlesList:userid',
        exact: true,
        component: ArticlesList,
    },
    {
        path: '/dashboard',
        exact: true,
        sidebarName: 'dashboard',
        component: dashboard,
    },
    {
        path: '/register',
        exact: true,
        component: register,
        settings,
    },
    {
        path: '/MemberArticles',
        exact: true,
        component: MemberArticles,
        'sidebarName': 'MemberArticles',
        auth: authRoles.leader,
    },
    {
        path: '/TrackingGoods',
        exact: true,
        component: TrackingGoods,
        'sidebarName': 'TrackingGoods',
        auth: authRoles.leader,
    },
    {
        path: '/MyshopperUser',
        exact: true,
        component: MyshopperUser,
        'sidebarName': 'MyshopperUser',
        auth: authRoles.leader,
    },
    {
        path: '/MyshopperNotifyRecord',
        exact: true,
        component: MyshopperNotifyRecord,
        'sidebarName': 'MyshopperNotifyRecord',
        auth: authRoles.leader,
    },
    {
        path: '/MemberView',
        exact: true,
        component: MemberView,
        'sidebarName': 'MemberView',
        auth: authRoles.leader,
    },
    {
        path: '/RoleList',
        component: MemberView,
        exact: true,
        sidebarName: 'RoleList',
        auth: authRoles.sa,
    },
];

export default routes;
