import React from "react";
import  dashboard from '@views/dashboard';
import Analytics from './Analytics';
import { authRoles } from 'auth/authRoles';

const dashboardRoutes = [
  {
    'path': "/dashboard/analytics",
    'component': Analytics,
    'sidebarName': 'Analytics',
    'exact': true,
    auth: authRoles.guest,
  }
];

export default dashboardRoutes;
