import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { queryShopperNotify } from '@actions/shopperNotifyAction';
import EnhancedTable, { SubRowAsync } from './react-table/EnhancedTable';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';

import makeData from './makeData';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
//international
import { useTranslation } from 'react-i18next';
import { fetchMenuList } from '@reducers/menuListSlice';
import store from '../store';
import Moment from 'react-moment';
import 'moment-timezone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import twLocale from 'date-fns/locale/zh-CN';
import styled from 'styled-components';
import moment from 'moment';
import FindReplaceTwoToneIcon from '@material-ui/icons/FindReplaceTwoTone';

const localeMap = {
    en: enLocale,
    tw: twLocale,
};

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`;

//const uuidv4 = require('uuid/v4');
const { v4: uuidV4 } = require('uuid');
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

function MyCell({
    value,
    columnProps: {
        rest: { someFunc },
    },
}) {
    return (
        <a href="#" onClick={someFunc}>
            {value}
        </a>
    );
}
function isValidDate(d) {
    //return d instanceof Date && !isNaN(d);
    return moment(d).isValid();
}

//store.dispatch(fetchMenuList());
const MyshopperNotifyRecord = ({ show_total }) => {
    let local_userid = JSON.parse(sessionStorage.getItem('usersession')).name;


    // The first commit of Material-UI
    const [endDate, setEndDate] = React.useState(new Date());

    let d_start = new Date();
    d_start.setMonth(d_start.getMonth() - 6);
    const [total, settotal] = React.useState(show_total);
    const [startDate, setstartDate] = React.useState(d_start);
    const handleDateChange = (date, value) => {
        setEndDate(new Date(moment(value).format('MM/DD/YYYY')));
    };
    const handleStartDateChange = (date, value) => {
        setstartDate(new Date(moment(value).format('MM/DD/YYYY')));
    };

    const handleSearch = () => {
        dispatch(
            queryShopperNotify({
                //userID: local_userid,
                beginDate: moment(startDate).format('MM/DD/YYYY'),
                endDate: moment(endDate).format('MM/DD/YYYY'),
            }),
        );
    };

    const menuStatus = useSelector((state) => state.menuLists.status);
    useEffect(() => {
        console.log(menuStatus);
        if (menuStatus === 'idle') {
            store.dispatch(fetchMenuList());
        }
    }, [menuStatus]);

    // get the status
    const postStatus = useSelector((state) => state.myshopperNotifyStatus.status);
    const posts = useSelector((state) => state.myshopperNotifyStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(postStatus);
        console.log(total);
        if (postStatus === 'idle' || total === undefined) {
            dispatch(
                queryShopperNotify({
                    //userID: local_userid,
                    beginDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                }),
            );
            settotal(true);
            //store.dispatch(fetchMenuList());
        }
    }, [postStatus, startDate, endDate, dispatch]);

    useEffect(() => {
        console.log(endDate);
        console.log(moment(endDate).format('MM/DD/YYYY'));
        console.log(postStatus);
        if (postStatus === 'idle' || postStatus === 'succeed') {
            dispatch(
                queryShopperNotify({
                    //userID: local_userid,
                    beginDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                }),
            );
        }
    }, [startDate, endDate, dispatch]);

    

    console.log(posts);
    console.log(startDate);
    let products;
    if (postStatus !== 'succeeded') products = [];
    else products = posts.products.data;

    const { t, i18n } = useTranslation();
    // header name definition
    const headerTitle = t('tracking.title');
    const headerID = t('tracking.userid');
    const headerPlatform = t('tracking.platform');
    const headerBasePrice = t('tracking.base_price');
    const headerTargetPrice = t('tracking.target_price');
    const headerNowPrice = t('tracking.now_price');
    const headerNoticeType = t('tracking.notice_type');
    const headerPhoto = t('tracking.photo');
    const headerProducts = t('tracking.products');
    const memberInteractions = t('tracking.interactions');
    const headerJoin = t('tracking.notify_time');
    const lblstartDate = t('tracking.startDate');
    const lblendDate = t('tracking.endDate');
    const language = t('tracking.language');
    const lblCancel = t('tracking.cancelbutton');
    const lblOk = t('tracking.okbutton');

    let totalcount = posts.products.count;

    if (!totalcount && postStatus !== 'succeeded')
        totalcount = t('main.loading');
    let showingUser = posts.products.query;
    console.log('showingUser:' + showingUser);
    if (total) showingUser = t('articles.all_articles');
    
    console.log(products);
    //const [data, setData] = React.useState([]);
    const [data, setData] = React.useState(
        React.useMemo(() => makeData(20), []),
    );
    console.log(data);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

   
    const [open, setOpen] = React.useState(false);
    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync
                row={row}
                rowProps={rowProps}
                visibleColumns={visibleColumns}
            />
        ),
        [],
    );

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    
      
    return (
        <div>
            <SearchBox>
                <div className="d-flex">
                    <Button
                        className="test_category"
                        startIcon={<AccountCircle />}
                    >
                        {showingUser}
                    </Button>
                    <span className="test_category">
                        {memberInteractions + ':' + totalcount}
                    </span>
                </div>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[language]}
                >
                    <Grid container className="date_box">
                        <KeyboardDatePicker
                            className="picker_box"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={lblstartDate}
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <KeyboardDatePicker
                            className="picker_box"
                            margin="normal"
                            id="date-picker-dialog"
                            label={lblendDate}
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <Button
                            onClick={handleSearch}
                            className="test_category"
                            startIcon={
                                <FindReplaceTwoToneIcon
                                    style={{ fontSize: 32 }}
                                />
                            }
                        ></Button>
                    </Grid>
                </MuiPickersUtilsProvider>
            </SearchBox>
            <CssBaseline />
            <MaUTable aria-label="simple table">
                <TableHead>
                <TableRow>
                
                    <TableCell align="center">{headerPhoto}</TableCell>
                    <TableCell align="center">{headerTitle}</TableCell>
                    <TableCell align="center">{headerPlatform}</TableCell>
                    <TableCell align="center">{headerJoin}</TableCell>
                    <TableCell align="center">{headerBasePrice}</TableCell>
                    <TableCell align="center">{headerTargetPrice}</TableCell>
                    <TableCell align="center">{headerNowPrice}</TableCell>
                    <TableCell align="center">{headerNoticeType}</TableCell>

                    
                </TableRow>
                </TableHead>
                <TableBody>
                {products
                    .sort( (a, b) => a.datetime < b.datetime?1:-1)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                    <TableRow key={row._id}>
                    <TableCell component="th" scope="row" align="center">
                    <div className="table_message">
                    <img
                                        className="thumbnail"
                                        src={row.snapshot}
                                        width={320}
                                        alt=""
                                    />
                                    </div>
                    </TableCell>
                    <TableCell>
                    <a
                                        href={`${row.product_url}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {row.title}
                                    </a>
                    </TableCell>
                    <TableCell align="center">{row.platform}</TableCell>
                    <TableCell align="center">{moment(row.datetime).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                    <TableCell align="center">{row.base_price}</TableCell>
                    <TableCell align="center">{row.target_price}</TableCell>
                    <TableCell align="center">{row.now_price}</TableCell>
                    <TableCell align="center">{row.notice_type}</TableCell>
                    
                    </TableRow>
                ))}
                </TableBody>
            </MaUTable>  
            <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />              
            
        </div>
    );
};

export default MyshopperNotifyRecord;
