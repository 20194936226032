/* jshint esversion: 8*/
import { QUERY_ARTICLES, QUERY_USERS } from './types';

import Config from '@src/Config';
const API_ROOT = Config.API_ROOT;
//const uuidv4 = require('uuid/v4');
const { v4: uuidv4 } = require('uuid');
const querystring = require('querystring');

//hank hacking need to fix.
const group = sessionStorage.getItem('selectedGroup');

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const queryArticles = (searchParams) => async (dispatch, getState) => {
    // Query Member List
    const userID = searchParams.userID;
    const beginDate = searchParams.beginDate;
    const endDate = searchParams.endDate;
    console.log('id:' + userID);
    console.log(searchParams);
    const group = JSON.parse(sessionStorage.getItem('selectedGroup'));

    dispatch({ type: 'ARTICLE_LOADING', payload: '', status: 'loading' });
    //for test to wait
    //await delay(2000);
    let searchUrl =
        API_ROOT +
        `group/${group.groupID}/member_articles?name=` +
        (userID ? encodeURI(userID) : '') +
        '&beginDate=' +
        (beginDate ? beginDate : '') +
        '&endDate=' +
        (endDate ? endDate : '');
    const user = JSON.stringify(sessionStorage.getItem('usersession'));
    const jwToken = user.token;
    console.log(searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Accept: '*/*',
            Authorization: 'Bearer ' + jwToken,
        },
    });
    let articles = await response.json();
    console.log(articles);
    articles.data.forEach((post) => {
        post.post_id = 'http://www.facebook.com/' + post.post_id;
    });
    articles.query = userID;
    dispatch({
        type: QUERY_ARTICLES,
        payload: articles,
    });
};
//hank temp solution to get member photo
const queryUsers = (searchParams) => async (dispatch) => {
    // Query Member List
    console.log('queryUsers');
    console.log('id:' + searchParams.profiles);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    //dispatch({ type: 'ARTICLE_LOADING', payload: '', status: 'loading' });
    //for test to wait
    //await delay(2000);
    let searchUrl =
        API_ROOT +
        `group/${selectGroup.groupID}/members?profiles=` +
        encodeURI(searchParams.profiles);

    console.log(searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Accept: '*/*',
            Authorization: `berear-${user.token}`,
        },
    });
    let users = await response.json();
    users.data.forEach((post) => {
        post.post_id = 'http://www.facebook.com/' + post.post_id;
    });

    dispatch({
        type: QUERY_USERS,
        payload: users.data,
    });
};

const updateArticleTag = (searchParams) => async (dispatch) => {
    // Query Member List
    console.log('updateArticleTag');
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    //dispatch({ type: 'ARTICLE_LOADING', payload: '', status: 'loading' });
    //for test to wait
    //await delay(2000);
    let searchUrl = API_ROOT + 'group/update_articles';

    console.log(searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    const payload = {
        tags: searchParams.tags,
        rows: searchParams.rows,
    };
    console.log(JSON.stringify(payload));
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: `berear-${user.token}`,
        },
        body: JSON.stringify(payload),
    })
        .then((resp) => {
            console.log(resp);
            resp.json();
            dispatch({
                type: 'UPDATE_ARTICLES_TAG',
                payload: payload,
                status: 'loading',
            });
            //dispatch(queryArticles({ userID: '邱以慈' }));
        })
        .catch((error) => console.error('Error:', error));
};

const queryMenuList = async (searchParams) => {
    // Query Member List
    console.log('queryMenuList');
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    let searchUrl = API_ROOT + 'group/menulist';

    console.log(searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Accept: '*/*',
            Authorization: `berear-${user.token}`,
        },
    });
    let ret = await response.json();

    return ret;
};

const addNewMenu = async (param) => {
    console.log(param);

    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    const payload = {
        id: uuidv4(),
        name: param.value,
        value: param.value,
        type: param.type,
        deleted: 0,
        order: param.order,
    };

    let searchUrl = API_ROOT + 'group/savenewmenu';
    console.log(payload);
    let ret;
    await fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: `berear-${user.token}`,
        },
        body: JSON.stringify(payload),
    })
        .then((resp) => {
            console.log(resp);
            ret = resp.json();
        })
        .catch((error) => console.error('Error:', error));
    return ret;
};

const updateExistMenu = async (param) => {
    console.log(param);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    const payload = {
        id: param.id,
        order: param.order,
        deleted: param.deleted,
    };

    let searchUrl = API_ROOT + 'group/update_menu';
    console.log(payload);
    let ret;
    await fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: `berear-${user.token}`,
        },
        body: JSON.stringify(payload),
    })
        .then((resp) => {
            console.log(resp);
            ret = resp.json();
        })
        .catch((error) => console.error('Error:', error));
    return ret;
};

export {
    queryArticles,
    updateArticleTag,
    queryUsers,
    queryMenuList,
    addNewMenu,
    updateExistMenu,
};
