/* jshint esversion: 8*/
import { QUERY_TRACKINGS, TRACKING_LOADING } from './types';

import Config from '@src/Config';
const API_ROOT = Config.API_ROOT;
//const uuidv4 = require('uuid/v4');
const { v4: uuidV4 } = require('uuid');
const querystring = require('querystring');

//hank hacking need to fix.
const group = sessionStorage.getItem('selectedGroup');

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const queryTrackings = (searchParams) => async (dispatch, getState) => {
    // Query Trakcing
    const userID = searchParams.userID;
    const beginDate = searchParams.beginDate;
    const endDate = searchParams.endDate;
    console.log('id:' + userID);
    console.log(searchParams);
    const group = JSON.parse(sessionStorage.getItem('selectedGroup'));

    dispatch({ type: 'TRACKING_LOADING', payload: '', status: 'loading' });
    //for test to wait
    //await delay(2000);
    let searchUrl =
        API_ROOT +
        `my_shopper/distinct_tracking_products` +
        '?beginDate=' +
        (beginDate ? beginDate : '') +
        '&endDate=' +
        (endDate ? endDate : '');
    const user = JSON.stringify(sessionStorage.getItem('usersession'));
    const jwToken = user.token;
    console.log(searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Accept: '*/*',
            Authorization: 'Bearer ' + jwToken,
        },
    });
    let products = await response.json();
    console.log(products);
    products.data.forEach((post) => {
        //post.post_id = 'http://www.facebook.com/' + post.post_id;
    });
    //articles.query = userID;
    dispatch({
        type: QUERY_TRACKINGS,
        payload: products,
    });
};

export { queryTrackings };
