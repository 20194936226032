import { SET_LAYOUT_SETTINGS } from '@actions/types';
import { LuxiLayoutSettings } from '@LuxiLayouts/settings';

const initialState = {
    settings: {
        ...LuxiLayoutSettings,
    },
};

const LayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAYOUT_SETTINGS:
            return {
                ...state,
                settings: { ...action.data },
            };
        default:
            return { ...state };
    }
};

export default LayoutReducer;
