import React, { Component, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { selectMenuLists, updateMenu } from '@reducers/menuListSlice';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`;

// fake data generator
const getItems = (menulist, type) => {
    const filter = menulist.filter((menu, index) => {
        return menu.type == type.type;
    });

    const ret = filter.map((ele, index) => {
        return { ...ele, id: index.toString(), content: ele.name };
    });

    return ret;
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const removeItem = (list, index) => {
    const result = Array.from(list);
    result.splice(index, 1);
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.06),0px 4px 5px 0px rgba(0, 0, 0, 0.042),0px 1px 10px 0px rgba(0, 0, 0, 0.036)',
    borderRadius: '4px',
    // change background colour if dragging
    background: isDragging ? 'var(--primary)' : 'var(--bg-paper)',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    borderRadius: '4px',
    background: isDraggingOver ? 'rgba(0,0,0, .1)' : 'var(--bg-default)',
    padding: grid,
    width: 250,
});

const SimpleListDnD = (type) => {
    const menulist = useSelector(selectMenuLists);
    let initialstate = getItems(menulist, type);
    //console.log(initialstate);
    const [items, setItems] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setItems(initialstate);
    }, [menulist, type]);
    const onDragEnd = (result) => {
        // dropped  outside the list
        console.log(result.destination.droppableId);
        if (result.destination.droppableId === 'deleteArea') {
            console.log('delete');
            let nobj = [];
            let oriID = items[result.source.index]._id;
            nobj.push({ id: oriID, deleted: 1 });
            dispatch(updateMenu({ items: nobj }));
            const new_items = removeItem(items, result.source.index);
            setItems(new_items);
            return;
        }

        if (result.destination.index < 0) return;

        const new_items = reorder(
            items,
            result.source.index,
            result.destination.index,
        );
        console.log(new_items);
        let oriID = items[result.source.index]._id;
        let filter = menulist.filter((ele) => ele.type == type.type);
        let maxCnt = Math.max(
            items[result.source.index].order,
            items[result.destination.index].order,
        );
        let swap = filter.filter((ele, order) => order <= maxCnt);
        let nobj = [];
        console.log(items[result.source.index].order);
        console.log(items[result.destination.index].order);
        console.log(maxCnt);
        if (items[result.source.index].order === maxCnt) {
            for (const ele of swap) {
                if (
                    ele.order < maxCnt &&
                    ele.order >= items[result.destination.index].order
                ) {
                    nobj.push({ id: ele._id, order: ele.order + 1 });
                }
            }
        } else {
            for (const ele of swap) {
                if (
                    ele.order <= maxCnt &&
                    ele.order > items[result.source.index].order
                ) {
                    nobj.push({ id: ele._id, order: ele.order - 1 });
                }
            }
        }
        nobj.push({ id: oriID, order: items[result.destination.index].order });
        setItems(new_items);
        dispatch(updateMenu({ items: nobj }));
    };
    return (
        <SearchBox>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <label for="activelist">清單</label>
                            {items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                            )}
                                        >
                                            {item.content}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <Droppable droppableId="deleteArea">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <label for="trashcan">垃圾桶</label>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </SearchBox>
    );
};

export default SimpleListDnD;
