import React, { useState } from "react";
import FacebookLoginWithButton from "react-facebook-login";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import history from "history.js";
import Modal from "react-bootstrap/Modal";
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    CircularProgress
} from "@material-ui/core";
import "@styles/signup.scss";
import Config from "@src/Config";
import "@styles/auth.scss";
import companyLogo from "../images/auth/wegroup_logo.png";
import Autocomplete, {
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
//google login
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

const LoginCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #eeeeeee5;
    height: 90%;
    width: 50vh;
    border-radius: 20px;
    margin-top: 5%;
    margin-bottom: 5%;

    @media (max-width: 788px) {
        margin: 0px;
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
    }
`;

const Title = styled.div`
    color: #6666ff;
    font-size: 30px;
    font-weight: bold;
`;

const TitleImg = styled.div`
    backgroundimage: "url(" + require("../images/material_bg.png") + ")";
`;

const SubTitle = styled.div`
    color: #6666ff;
    font-size: 20px;
    font-weight: 600;
`;

const Wrapper = styled.div`
    margin: 40px 40px 40px 40px;
    border-radius: 50px;
`;

const componentClicked = () => {
    console.log("Clicked!");
};

const LoginButton = ({ facebookResponse }) => (
    <FacebookLoginWithButton
        appId="2414851492097790"
        // autoLoad
        fields="name,email,picture"
        size="small"
        onClick={componentClicked}
        callback={facebookResponse}
        icon="fa-facebook"
    />
);

const GroupSelector = ({ user }) => {
    const [show, setShow] = useState(true);

    let option = [];
    let groupList = [];
    let hash = {};
    groupList = sessionStorage.getItem("GroupLists").split(",");
    console.log(groupList);
    groupList.forEach((op) => {
        let arr = op.split(":");
        hash[arr[0]] = arr[1];
        option.push(arr[0]);
    });
    console.log(option);
    const [selectedGroup, setselectedGroup] = useState(option[0]);
    const handleClose = () => {
        console.log(selectedGroup);
        console.log(hash[selectedGroup]);
        sessionStorage.setItem(
            "selectedGroup",
            JSON.stringify({ groupID: hash[selectedGroup] }) //need to fix
        );
        console.log(selectedGroup);
        history.push({
            pathname: "/dashboard/analytics"
        });
    };

    const handleChange = (event, value) => {
        setselectedGroup(value);
        console.log(selectedGroup);
    };
    const { t, i18n } = useTranslation();

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{t("login.choosegroup")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Autocomplete
                        id="tags-standard"
                        options={option}
                        getOptionLabel={(option) => option}
                        defaultValue={option[0]}
                        style={{ width: 550 }}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label=""
                                defaultValue={option[0]}
                                placeholder="社團名稱"
                            />
                        )}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const MainScreen = ({ user }) => (
    <>
        <Redirect to="/dashboard/analytics" />
    </>
);

const RegisterButton = (props) => {
    const onClick = () =>
        history.push({
            pathname: "/register"
        });

    return (
        <SubTitle
            style={{
                padding: "10px",
                width: "100%",
                textAlign: "center"
            }}
            onClick={onClick}
            {...props}
        >
            立即註冊
        </SubTitle>
    );
};

const DemoButton = (props) => {
    return (
        <SubTitle
            style={{
                padding: "10px",
                width: "100%",
                textAlign: "center"
            }}
            {...props}
        >
            試用登入
        </SubTitle>
    );
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                LuxurAI
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
class Login extends React.Component {
    state = { user: false, choosegroup: false };

    facebookResponse = (response) => {
        console.log(response);
        // *** login api to get JWT here
        this.luxuraiLogin(null, response.id, this.doneLogin);
    };

    constructor(props) {
        super(props);
        this.clientId =
            "579678893251-iep74mdj7ms5fjqsp4qvn2grv2i7d96l.apps.googleusercontent.com";
        this.luxuraiLogin = this.luxuraiLogin.bind(this);
    }

    componentDidMount = () => {
        const initClient = () => {
            gapi.client.init({
                clientId: this.clientId,
                scope: ""
            });
        };
        gapi.load("client:auth2", initClient);
    };

    componentDidUpdate = () => {
        let google_id = sessionStorage.getItem("new_google_id");
        let fb_id = sessionStorage.getItem("new_fb_id");
        if (google_id) {
            this.luxuraiLogin(google_id, fb_id, this.doneLogin);
            sessionStorage.removeItem("new_google_id");
        } else if (fb_id) {
            this.luxuraiLogin(google_id, fb_id, this.doneLogin);
            sessionStorage.removeItem("new_fb_id");
        }
    };
    clickMe = (event) => {
        this.luxuraiLogin(null, null, this.doneLogin);
    };

    async luxuraiLogin(google_id, fb_id, callback) {
        const API = `${Config.API_ROOT}login`;
        console.log(Config.LOCAL_DEBUG == "true");
        console.log(google_id);
        //if (Config.LOCAL_DEBUG == 'true') fb_id = Config.LOCAL_DEBUG_USER.id;
        //if (typeof fb_id != 'string') fb_id = Config.DEMO_ID;
        if (Config.LOCAL_DEBUG == "true")
            google_id = Config.LOCAL_DEBUG_USER.googleId;
        //if (typeof fb_id != 'string') google_id = Config.DEMO_GOOGLEID;
        console.log(google_id);
        const reqBody = JSON.stringify({ google_id: google_id, fb_id: fb_id });
        console.log(reqBody);
        let response = await fetch(API, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: reqBody
        });
        const jsonObj = await response.json();
        console.log(jsonObj.success);
        if (jsonObj.success == false) {
            history.push({
                pathname: "/register"
            });
        }
        console.log(jsonObj);
        sessionStorage.setItem("usersession", JSON.stringify(jsonObj));
        console.log("group:" + jsonObj.groups);
        let grouplist = [];
        jsonObj.groups.forEach((group) => {
            console.log(group);
            grouplist.push(group.groupName + ":" + group.groupID);
        });
        //grouplist.push({groupAlias: "raymond30", groupID: "269414800695314", groupName: "雷蒙三十｜個體經營者 x 生活黑客成長社群｜個體小聚｜Podcast"});
        sessionStorage.setItem("GroupLists", grouplist);
        console.log(JSON.stringify(jsonObj.groups[0]));
        if (grouplist.length === 1) {
            sessionStorage.setItem(
                "selectedGroup",
                JSON.stringify(jsonObj.groups[0])
            );
            this.setState({ ...this.state, choosegroup: false });
        } else this.setState({ ...this.state, choosegroup: true });
        console.log(this.state.choosegroup);
        console.log(jsonObj);
        if (jsonObj.googleId != "undefined")
            this.setState({ ...this.state, user: jsonObj.googleId });
        else this.setState({ ...this.state, user: jsonObj.jsonObj.id });
    }

    onSuccess = (response) => {
        console.log("success:", response);
        this.luxuraiLogin(response.googleId, null, this.doneLogin);
    };
    onFailure = (err) => {
        console.log("failed:", err);
    };

    // TODO: update web ui with user info
    // TODO: login with JWT to update user token, with JWT db storage in unnecessary.
    // TODO: save barrer token in local storage

    render() {
        return (
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
                        <div className="row w-100">
                            <div className="col-lg-4 mx-auto">
                                <div
                                    className="auto-form-wrapper"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    {this.state.user ? (
                                        this.state.choosegroup === false ? (
                                            <MainScreen
                                                user={this.state.user}
                                            />
                                        ) : (
                                            <>
                                                <GroupSelector
                                                    user={this.state.user}
                                                ></GroupSelector>
                                            </>
                                        )
                                    ) : (
                                        <LoginCard className="title_log">
                                            <img
                                                src={companyLogo}
                                                alt="import"
                                                width="200"
                                            />

                                            <br></br>
                                            <Title>社團管家公</Title>
                                            <br></br>
                                            <br></br>
                                            <form action="#">
                                                <div></div>
                                                <div>
                                                    <GoogleLogin
                                                        clientId={this.clientId}
                                                        buttonText="Sign in with Google"
                                                        onSuccess={
                                                            this.onSuccess
                                                        }
                                                        onFailure={
                                                            this.onFailure
                                                        }
                                                        cookiePolicy={
                                                            "single_host_origin"
                                                        }
                                                        isSignedIn={false}
                                                    />

                                                    <RegisterButton></RegisterButton>
                                                    <DemoButton
                                                        onClick={this.clickMe}
                                                    ></DemoButton>
                                                </div>
                                            </form>
                                            <Copyright />
                                        </LoginCard>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
