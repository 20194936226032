import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import TabView from '../components/Tabview';
import { spacing, typography } from '@material-ui/system';
//import white from '@material-ui/core/colors/white';
import { blue600, blue400 } from '@material-ui/core/colors/blue';
import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LogoutIcon from '@material-ui/icons/Loyalty';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ListIcon from '@material-ui/icons/List';
import { Switch, Route } from 'react-router-dom';
import routes from '../router.js';
import Config from '@src/Config';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    lefttoolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundImage: 'url(' + require('../images/material_bg.png') + ')',
        padding: theme.spacing(0, 1),
        height: 90,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        height: '90px',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
}));

export default function MiniDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const onItemClick = (title) => () => {};

    let user = JSON.parse(sessionStorage.getItem('usersession'));
    console.log('====' + user.name);
    
    const styles = {
        logo: {
            cursor: 'pointer',
            fontSize: 22,
            color: typography.textFullWhite,
            lineHeight: `${spacing.desktopKeylineIncrement}px`,
            fontWeight: typography.fontWeightLight,
            backgroundColor: blue600,
            paddingLeft: 40,
            height: 56,
        },
        menuItem: {
            color: { color: 'white' },
            fontSize: 14,
        },
        avatar: {
            div: {
                padding: '15px 0 20px 15px',
                backgroundImage:
                    'url(' + require('../images/material_bg.png') + ')',
                height: 45,
            },
            icon: {
                float: 'left',
                display: 'block',
                marginRight: 5,
                boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)',
            },
            span: {
                paddingTop: 12,
                display: 'block',
                color: 'white',
                fontWeight: 300,
                textShadow: '1px 1px #444',
            },
        },
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar style={{ height: ' 90px', background: '#6fafeb' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <nav>
                        <Typography component={'span'} variant="h6" noWrap>
                            會員大數據
                        </Typography>
                    </nav>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.lefttoolbar}>
                    <Avatar
                        src={user.profileObj.imageUrl}
                        size={50}
                        style={styles.avatar.icon}
                    />
                    <span style={styles.avatar.span}>{user.profileObj.name}</span>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem
                        button
                        component={Link}
                        to="/dashboard"
                        onClick={onItemClick('Home')}
                    >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText>整體報表</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/MemberList"
                        onClick={onItemClick('紛絲名單')}
                    >
                        <ListItemIcon>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText>紛絲名單</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/ArticlesList"
                        onClick={onItemClick('貼文分析')}
                    >
                        <ListItemIcon>
                            <PostAddIcon />
                        </ListItemIcon>
                        <ListItemText>貼文分析</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/dashboard"
                        onClick={onItemClick('訊息推播')}
                    >
                        <ListItemIcon>
                            <RateReviewIcon />
                        </ListItemIcon>
                        <ListItemText>訊息推播</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/dashboard/analytics"
                        onClick={onItemClick('貼文標籤')}
                    >
                        <ListItemIcon>
                            <LoyaltyIcon />
                        </ListItemIcon>
                        <ListItemText>貼文標籤</ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem
                        button
                        component={Link}
                        to="/MenuDesign"
                        onClick={onItemClick('設定')}
                    >
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText>設定</ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/UserManagement"
                        onClick={onItemClick('人員管理')}
                    >
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText>人員管理</ListItemText>
                    </ListItem>
                    {['幫助', '回報問題'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}                 
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <TabView />
            </main>
        </div>
    );
}
