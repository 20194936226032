import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import '../styles/main.css';
import {searchShopee} from '@actions/shopeeAction';

class StoreList extends Component {

    constructor(props){
        super(props);
        this.state={
            keyword:""
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        // this.props.fetchStore();
    }

    componentWillReceiveProps(nextProps){

        this.props = nextProps;
    }

    onChange(e){
        this.setState( { [e.target.name] : e.target.value } );
    }

    onSubmit(e){
        e.preventDefault();
        if (this.state.keyword===''){
            alert('請輸入關鍵字');
            return;
        }
        // call action
        this.props.searchShopee(this.state);
    }
    
    render() {
        const NTD_NORMALIZER = 100000;
        const arr = (this.props.stores? this.props.stores:[]);
        const Items = arr.map( unit => (
            <div className="pdBox" key={unit.itemid}>
                <div className="pdBox_info">
                    <Link to={'/drinkMenu?store='+unit.itemid}>{unit.price_min/NTD_NORMALIZER + '-' + unit.price_max/NTD_NORMALIZER + '\t' + unit.name + '\t'}</Link>
                </div>
            </div>
        ));


        return (
            <div>
                <form id='form' onSubmit={this.onSubmit}>

                    <div>
                        <label>關鍵字:</label>
                        <input type='text' name='keyword' onChange={this.onChange} value={this.state.keyword}/>
                    </div>
                    <div>
                        <label>新舊:</label>
                        <select name='condition' value={this.state.condition} onChange={this.onChange}>
                            <option value='new'>全新</option>
                            <option value='used'>二手</option>
                        </select>
                    </div>
                    <div>
                        <label>排序:</label>
                        <select name='by' value={this.state.condition} onChange={this.onChange}>
                            <option value='relevancy'>相關性</option>
                            <option value='price'>價格</option>

                        </select>
                        <select name='order' value={this.state.condition} onChange={this.onChange}>
                            <option value='asc'>小->大</option>
                            <option value='desc'>大->小</option>
                        </select>                        
                    </div>                    
                    <div>
                        <span>
                            <label>最低價</label>
                        </span>
                        <span>
                            <input type='text' name='price_min' onChange={this.onChange} value={this.state.price_min}></input>
                        </span>
                        <span>
                            <label>最高價</label>
                        </span>
                        <span>
                            <input type='text' name='price_max' onChange={this.onChange} value={this.state.price_max}></input>                        
                        </span>
                    </div>
                    <div>
                        <button type='submit'>Submit</button>
                    </div>   
                </form>
                <h3 className="pd_category">Shopee 搜尋結果</h3>
                <div className="pdlist">
                    {Items}
                </div>               
            </div>
        )
    }
}

const mapStateToProps = state => { 
    return (
        { 
            stores: state.menuStates.stores
        }
    );
}


export default connect(mapStateToProps, { searchShopee })(StoreList)
