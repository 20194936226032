/* eslint-disable import/no-anonymous-default-export */
/* jshint esversion: 9*/
import { QUERY_TRACKINGS } from '@actions/types';

const initialState = {
    products: [],
    status: 'idle',
    error: null,
    usersLoading: 'idle'
};

export default function(state = initialState, action) {
    switch (action.type){
        case QUERY_TRACKINGS:
            return {
                ...state,
                products: action.payload,
                status: 'succeeded'
            };
        default:
            return {...state};
    }
}