import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import articleReducer from './articleReducer';
import menuListSlice from './menuListSlice';
import LayoutReducer from './LayoutReducer';
import dashboardSlice, {
    dashboardMembers,
    dashboardInteractions,
    dashboardArticlesType,
    dashboardActiveMembers,
    dashboardAllMembers,
    dashboardTotalArticles,
    dashboardAllArticles,
    dashboardTrackings,
} from './dashboardSlice';
import userReducer from 'redux/reducers/UserReducer';
import trackingReducer from './trackingReducer';
import shopperuserReducer from './shopperuserReducer';
import myshopperNotifyReducer from './myshopperNotifyReducer';

export default {
    user: userReducer,
    menuStates: searchReducer,
    articleStates: articleReducer,
    trackingStates: trackingReducer,
    shopperuserStatus: shopperuserReducer,
    myshopperNotifyStatus: myshopperNotifyReducer,
    menuLists: menuListSlice,
    LayoutSetting: LayoutReducer,
    dashboardArticles: dashboardSlice,
    dashboardMembers: dashboardMembers.reducer,
    dashboardInteractions: dashboardInteractions.reducer,
    dashboardArticlesType: dashboardArticlesType.reducer,
    dashboardActiveMembers: dashboardActiveMembers.reducer,
    dashboardAllMembers: dashboardAllMembers.reducer,
    dashboardTotalArticles: dashboardTotalArticles.reducer,
    dashboardAllArticles: dashboardAllArticles.reducer,
    dashboardAllTrackings: dashboardTrackings.reducer
};
/*
export default combineReducers(
    {
        menuStates: searchReducer,
        articleStates: articleReducer,
        menuListsStates: menuListReducer
    }
);*/
