import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-spinners-css';
import styled from 'styled-components';
import '../styles/member.css';
import {
    queryMember,
    filterMember,
    memberCheckAll,
    memberCheck,
} from '@actions/memberActions';
import { Translation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AtListIcon from '@material-ui/icons/AlternateEmail';
import Icon from '@material-ui/core/Icon';
import { fade, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { queryArticles } from '@actions/articleAction';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import UpdateTagDialog from './react-table/UpdateTagDialog.js';
import { Checkbox } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { format } from 'date-fns';
import { et } from 'date-fns/locale';
import DoughnutChart from 'views/charts/echarts/Doughnut';
import _ from 'lodash';

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 25px;
    height: 40px;
    background-color: #eeeeee;
`;

const Tab = styled.div`
    padding: 5px;
    height: 30px;
    margin-left: 10px;
    line-height: 20px;
    background-color: #eeeeee;
    border-radius: 3px;
    font-weight: 400;
    color: #333333;
    &:hover {
        background-color: #cccccc;
    }
`;

const SearchContainer = styled.div`
    background-color: #eeeeee;
    min-height: 120px;
    padding-top: 25px;
`;

const SearchCard = styled.div`
    background-color: #ffffff;
    height: 100px;
    margin: 10px 25px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 3px 0px #dfcdcd;
`;

const SearchBox = styled.div`
    width: calc(100%-80px);
    height: 40px;
    margin: 30px 40px;
    border-radius: 20px;
    border: 1px solid #aaaaaa;
`;

const SearchOptionList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 40px;
    height: 40px;
    flex-wrap: wrap;
`;

const SearchOption = styled.div`
    padding: 5px;
    height: 30px;
    margin: 5px 5px;
    line-height: 20px;
    background-color: #eeeeee;
    border-radius: 3px;
    font-weight: 400;
    color: #333333;
`;

const OrderTitle = styled.p`
    color: #0000ee;
`;

const COLOR_SELECTED = '#AAAAAA';
const COLOR_UNSELECTED = '#EEEEEE';

let queryObj = {
    total: 0,
    comment: 0,
    like: 0,
    order: 0,
    amount: 0,
};

let sortOrder = '遞減';
const useStyles = (theme) => ({
    link: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
});
export class MemberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberCheckList: [],
            fetchInState: false,
        };
        this.selectedUsers = {};
        this.selectedRow = {};
        this.defaultOption = React.createRef();
        this.selectedOption = {};
        this.onChange = this.onChange.bind(this);
        this.onViewInterations = this.onViewInterations.bind(this);
        this.selectedTags = this.selectedTags.bind(this);
        this.getOriginalTags = this.getOriginalTags.bind(this);
        this.getAtList = this.getAtList.bind(this);
        //check if this is under dashboard
        if (this.props.passedFunction) this.props.passedFunction.bind(this);
    }

    updateTagHandler = (user) => {
        console.log('updateTagHandler');
    };

    getAtList = () => {
        console.log(JSON.stringify(this.selectedUsers));
        let atListStr = '';
        const arr = this.props.members ? this.props.members.data : [];
        const selectedUsersObj = this.selectedUsers ? this.selectedUsers : {};
        const selectedUsers = Object.keys(selectedUsersObj);
        for (let item of arr) {
            if (selectedUsers.includes(item._id)) {
                atListStr += `@${item.userName} `;
            }
        }

        navigator.clipboard.writeText(atListStr);
        setTimeout(() => {
            alert(`名單已存入剪貼簿`);
        }, 1);
    };

    getOriginalTags() {
        let tagSet = new Set();
        const arr = this.props.members ? this.props.members.data : [];
        const selectedUsersObj = this.selectedUsers ? this.selectedUsers : {};
        const selectedUsers = Object.keys(selectedUsersObj);
        for (let item of arr) {
            if (item._id && selectedUsers.includes(item._id[0])) {
                if (!item.tags) {
                    item.tags = [];
                }
                for (let tag of item.tags) {
                    tagSet.add(tag);
                    console.log(tag);
                }
            }
        }
        console.log(tagSet);
        return tagSet;
    }

    onTagUpdated(e) {
        console.log(`selectedUsers: ${JSON.stringify(this.selectedUsers)}`);

        if (e.target.id === '*') {
            for (let member of this.props.members.data) {
                this.selectedUsers[member.groupUserID] = true;
            }
            this.props.memberCheckAll(this.props.members, e.target.checked);
            return;
        }

        console.log(`state.memberCheckList: ${this.state.memberCheckList}`);

        this.props.memberCheck(
            this.props.members,
            e.target.id,
            e.target.checked,
        );
        if (e.target.checked) {
            this.selectedUsers[e.target.id] = true;
        } else {
            delete this.selectedUsers[e.target.id];
        }
        for (let key in this.selectedUsers) {
            console.log(`tag updated: ${key} ${this.selectedUsers[key]}`);
        }
    }

    selectedTags = () => {
        let tagSet = new Set();
        tagSet.add('abc');
        console.log('tag:' + tagSet.size);
        return tagSet;
    };

    componentDidMount() {
        this.props.queryMember({});
    }

    shouldComponentUpdate(nextProps, nextStates) {
        this.props = nextProps;
        return true;
    }

    onChange(e) {
        let sortField = '';
        var queryObj = {};
        console.log(e.target.name);

        // Handle checkbox update
        if (e.target.name === 'gilad') {
            this.onTagUpdated(e);
            return;
        }

        switch (e.target.id) {
            case 'order':
                sortOrder = sortOrder === '遞增' ? '遞減' : '遞增';
                sortField = this.selectedOption
                    ? this.selectedOption.id
                    : 'likes';
                queryObj = { sortBy: sortField };
                queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
                this.props.queryMember(queryObj);
                return;
                break;
            case 'nextPage':
                sortField = this.selectedOption
                    ? this.selectedOption.id
                    : 'likes';
                queryObj = { sortBy: sortField };
                queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
                queryObj.page = this.props.members.page + 1;
                this.props.queryMember(queryObj);
                break;
            case 'prevPage':
                sortField = this.selectedOption
                    ? this.selectedOption.id
                    : 'likes';
                queryObj = { sortBy: sortField };
                queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
                queryObj.page = Math.max(0, this.props.members.page - 1);
                this.props.queryMember(queryObj);
                break;
            case 'searchName':
                sortField = this.selectedOption
                    ? this.selectedOption.id
                    : 'likes';
                queryObj = { sortBy: sortField };
                queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
                queryObj.page = Math.max(0, this.props.members.page - 1);
                console.log('input:' + e.target.value);
                queryObj.userName = e.target.value;
                console.log('queryObj:' + JSON.stringify(queryObj));
                this.props.queryMember(queryObj);
                break;

            default:
                if (!this.selectedOption.style) {
                    this.defaultOption.current.style.backgroundColor =
                        COLOR_UNSELECTED;
                } else {
                    this.selectedOption.style.backgroundColor =
                        COLOR_UNSELECTED;
                }
                this.selectedOption = e.target;
                e.target.style.backgroundColor = COLOR_SELECTED;
                queryObj = { sortBy: e.target.id };
                queryObj.order = sortOrder === '遞增' ? '遞減' : '遞增';
                this.props.queryMember(queryObj);
                break;
        }
    }

    onNext(e) {
        this.setState({ ...this.state, fetchInState: false });
        let sortField = this.selectedOption
            ? this.selectedOption.id
            : 'comments';
        let queryObj = { sortBy: sortField };
        queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
        queryObj.page = this.props.members.page + 1;
        this.props.queryMember(queryObj);
        return;
    }

    onSubmit(e) {
        e.preventDefault();
        console.log('on submit');

        if (this.state.keyword === '') {
            alert('請輸入關鍵字');
            return;
        }
        // call action
        this.props.searchShopee(this.state);
    }

    onTapDelete(e) {
        const user = JSON.parse(sessionStorage.getItem('user'));
        let group = JSON.parse(sessionStorage.getItem('selectedGroup'));
        window.open(
            'https://www.facebook.com/groups/' + group.groupID + '/members',
        );
        navigator.clipboard.writeText(e.target.attributes.username.value);
    }

    onViewInterations(e) {
        //const dispatch = useDispatch();
        //console.log(unit.userName);
        console.log(e.currentTarget.attributes.userName);
        let queryObj = { sortBy: '' };
        queryObj.order = sortOrder === '遞增' ? 'asc' : 'desc';
        queryObj.page = this.props.members.page + 1;
        console.log(queryObj);
        //console.log({userID:e.currentTarget.attributes.userName});

        this.props.queryArticles({
            userID: e.currentTarget.attributes.userName.value,
            beginDate: '01/01/2021',
            endDate: format(new Date(), 'MM/dd/yyyy'),
        });
        this.props.passedFunction(e, 1);
        //dispatch(queryArticles({userID:e.currentTarget.attributes.userName}));
    }

    render() {
        //const { t } = useTranslation();
        const { classes } = this.props;
        const arr = this.props.members ? this.props.members.data : [];
        const totalPages = this.props.members ? this.props.members.total : 0;
        let nMembers = this.props.members ? this.props.members.count : 0;
        let page = this.props.members ? this.props.members.page : 0;
        let group = JSON.parse(sessionStorage.getItem('selectedGroup'));
        sessionStorage.setItem('nMembers', nMembers);

        let index = 0;
        let checkList = {};

        for (let member of arr) {
            member.tagTrend = [];
            if (
                _.isEmpty(member.articleTags) ||
                Object.keys(member.articleTags).length <= 1
            ) {
                member.tagTrend.push({ value: 1, name: '無偏好' });
                continue;
            }
            console.log(member.articleTags);
            let sum = 0;
            checkList[member.groupUserID] = false;
            for (let key in member.articleTags) {
                if (key === '') continue;
                member.tagTrend.push({
                    value: member.articleTags[key],
                    name: key,
                });
            }
            console.log('articleTags:');
            console.log(JSON.stringify(member.articleTags));
        }

        let member = {};
        if (arr.length > 0) {
            member = arr[0];
        }

        let weightComment = 2;
        let weightLike = 1;

        // console.log(JSON.stringify(member));
        const Items = arr.map((unit) => (
            <div className="member_card" key={unit.groupUserID}>
                <div>
                    <Checkbox
                        checked={unit.checked}
                        id={unit.groupUserID}
                        onChange={this.onChange}
                        name="gilad"
                    />
                </div>
                <div className="profile">
                    <a
                        className="name"
                        target="_blank"
                        href={`https://www.facebook.com/groups/${group.groupID}/user/${unit.groupUserID}`}
                    >
                        {unit.userName}
                    </a>
                    <img
                        style={{ borderRadius: '50%' }}
                        src={unit.photo}
                        alt="photo"
                        width="100"
                        height="100"
                    ></img>
                    <div className="options">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={this.onViewInterations}
                            className={classes.link}
                            username={unit.userName}
                            inputprops={{
                                'data-role': 'role',
                            }}
                            startIcon={<DescriptionIcon />}
                        >
                            互動資料
                        </Button>
                    </div>
                </div>
                <div className="middle_tag_container">
                    <div className="tag_list">
                        {unit.tags ? (
                            unit.tags.map((tag) => (
                                <div className="tag">{tag} </div>
                            ))
                        ) : (
                            <div className="tagempty"> </div>
                        )}
                    </div>
                    <div className="articletag">
                        <div className="card-title">
                            <big
                                className="NoCredentialsErrortext-gray"
                                style={{ fontSize: '20px' }}
                            >
                                <Translation>
                                    {(t, { i18n }) => (
                                        <li>{t('memberlist.articleTag')}</li>
                                    )}
                                </Translation>
                            </big>
                        </div>
                        <DoughnutChart
                            height="150px"
                            width="160px"
                            data={unit.tagTrend}
                            color={[
                                '#52D726',
                                '#FFEC00',
                                '#FF7300',
                                '#FF0000',
                                '#007ED6',
                                '#7CDDDD',
                            ]}
                        />
                    </div>
                </div>
                <div className="side_options">
                    <div className="scores">
                        <ul className="total_score">
                            <li>綜合分數</li>
                            <li>
                                {unit.hasOwnProperty('likes')
                                    ? unit.likes * weightLike +
                                      unit.comments * weightComment
                                    : 0}
                            </li>
                        </ul>
                    </div>
                    <div className="scores">
                        <ul>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i className="fas fa-comments score_icon">
                                            {t('memberlist.message')}
                                        </i>
                                    )}
                                </Translation>
                                {unit.hasOwnProperty('likes')
                                    ? unit.comments
                                    : 0}
                            </li>

                            <li>
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i className="fa fa-heart score_icon">
                                            {t('memberlist.thumbsup')}
                                        </i>
                                    )}
                                </Translation>
                                {unit.hasOwnProperty('likes') ? unit.likes : 0}
                            </li>

                            <li>
                                <i className="fa fa-shopping-cart score_icon">
                                    訂單
                                </i>
                                {unit.hasOwnProperty('likes') ? unit.orders : 0}
                            </li>

                            <li>
                                <i className="fas fa-hand-holding-usd score_icon">
                                    金額
                                </i>
                                {unit.hasOwnProperty('likes')
                                    ? unit.purchase
                                    : 0}
                            </li>
                        </ul>
                    </div>
                    <div className="options">
                        <button
                            username={unit.userName}
                            onClick={this.onTapDelete}
                            className="tag_button"
                        >
                            複製並刪除
                        </button>
                    </div>
                </div>
            </div>
        ));

        return (
            <React.Fragment>
                <SearchContainer>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <p
                            style={{
                                fontSize: '17px',
                                fontWeight: 400,
                                paddingLeft: '30px',
                            }}
                        >
                            會員搜尋
                        </p>
                    </div>
                    <SearchCard>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <Translation>
                                {(t, { i18n }) => (
                                    <InputBase
                                        id="searchName"
                                        placeholder={t('memberlist.searchbar')}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        onChange={this.onChange}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                )}
                            </Translation>
                        </div>
                        <SearchOptionList>
                            <OrderTitle>排序欄位</OrderTitle>
                            <SearchOption id="comments" onClick={this.onChange}>
                                留言
                            </SearchOption>
                            <SearchOption
                                id="likes"
                                onClick={this.onChange}
                                style={{ backgroundColor: COLOR_SELECTED }}
                                ref={this.defaultOption}
                            >
                                按讚
                            </SearchOption>
                            <SearchOption id="orders" onClick={this.onChange}>
                                訂單
                            </SearchOption>
                            <SearchOption id="purchase" onClick={this.onChange}>
                                金額
                            </SearchOption>
                            <SearchOption
                                id="order"
                                value="asc"
                                onClick={this.onChange}
                            >
                                排序：{sortOrder}
                            </SearchOption>
                        </SearchOptionList>
                    </SearchCard>
                </SearchContainer>
                <TabContainer>
                    <Checkbox
                        style={{ paddingTop: '24px' }}
                        id="*"
                        onChange={this.onChange}
                        name="gilad"
                    />
                    <UpdateTagDialog
                        updateTagHandler={this.updateTagHandler}
                        selectedRow={this.selectedUsers}
                        originalTag={this.getOriginalTags}
                        userID={'userID'}
                        mode="member_tag"
                    />
                    <Tooltip title="Update">
                        <IconButton
                            style={{ paddingTop: '24px' }}
                            aria-label="update"
                            onClick={this.getAtList}
                        >
                            <AtListIcon />
                        </IconButton>
                    </Tooltip>
                </TabContainer>
                <div className="list_container">{Items}</div>
                <TabContainer>
                    <Translation>
                        {(t, { i18n }) => (
                            <Tab id="prevPage" onClick={this.onChange}>
                                {' '}
                                {t('memberlist.prevpage')}
                            </Tab>
                        )}
                    </Translation>
                    <NavigateBeforeIcon
                        style={{ backgroundColor: '#9ec7e9' }}
                    />
                    <Tab>
                        <Translation>
                            {(t, { i18n }) => <i>{t('memberlist.pagehead')}</i>}
                        </Translation>
                        {page}/{totalPages}
                        <Translation>
                            {(t, { i18n }) => <i>{t('memberlist.pageunit')}</i>}
                        </Translation>
                    </Tab>

                    <Translation>
                        {(t, { i18n }) => (
                            <Tab id="nextPage" onClick={this.onChange}>
                                {t('memberlist.nextpage')}
                            </Tab>
                        )}
                    </Translation>
                    <NavigateNextIcon style={{ backgroundColor: '#9ec7e9' }} />
                </TabContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        members: state.menuStates.stores,
        articlesTypes: state.dashboardArticlesType,
    };
};

//export default translate()(MemberList);
export default connect(mapStateToProps, {
    queryMember,
    queryArticles,
    filterMember,
    memberCheckAll,
    memberCheck,
})(withStyles(useStyles)(MemberList));
