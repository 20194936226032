/* jshint esversion: 9 */
import { QUERY_MENULIST } from '@actions/types';
import { queryMenuList, addNewMenu, updateExistMenu } from '@actions/articleAction';
import {
    createSlice,
    createSelector,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

export const fetchMenuList = createAsyncThunk(
    'menuList/fetchMenuList',
    async () => {
        const response = await queryMenuList({});
        console.log('fetchMenuList');
        console.log(response);
        return response.menuLists;
    },
);

export const saveNewMenu = createAsyncThunk(
    'menuList/saveNewMenu',
    async (params) => {
        const response = await addNewMenu(params);
        console.log('saveNewMenu');
        console.log(response);
        return response.menuLists[0];
    },
);

export const updateMenu = createAsyncThunk(
    'menuList/updateMenu',
    async (params) => {
        let response;
        for( const item of params.items){
            response = await updateExistMenu(item);
            console.log('updateMenu');
            console.log(response);
        }
        
        return response.menuLists[0];
    },
);

const menuListAdapter = createEntityAdapter();

const initialState = menuListAdapter.getInitialState({
    status: 'idle',
});

const menuListSlice = createSlice({
    name: 'menuList',
    initialState,
    reducers: {
        menuListAdded(state, action) {
            // This "mutating" code is okay inside of createSlice!
            //state.push(action.payload);
        },
        menuListSelected: {
            reducer(state, action) {
                const { color, todoId } = action.payload;
                state.entities[todoId].color = color;
            },
            prepare(todoId, color) {
                return {
                    payload: { todoId, color },
                };
            },
        },
        menuListUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingPost = state.posts.find((post) => post.id === id);
            if (existingPost) {
                existingPost.title = title;
                existingPost.content = content;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenuList.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchMenuList.fulfilled, (state, action) => {
                console.log(action.payload);
                console.log(state);
                menuListAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            })
            .addCase(saveNewMenu.fulfilled, menuListAdapter.addOne)
            .addCase(updateMenu.fulfilled, (state, action) => {
                console.log("updateMenu");
            });
    },
});

export const { selectAll: selectMenuLists } = menuListAdapter.getSelectors(
    (state) => state.menuLists,
);

export const selectMenu = createSelector(
    // First, pass one or more "input selector" functions:
    selectMenuLists,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (menus) => menus.map((menu) => menu.value),
);

export const { menuListAdded, menuListSelected } = menuListSlice.actions;

export default menuListSlice.reducer;
