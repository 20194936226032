//global utility
export const QUERY_MENULIST = 'QUERY_MENULIST';

//member related
export const SEARCH_SHOPEE = 'SEARCH_SHOPEE';
export const GET_POPLIST = 'GET_POPLIST';
export const QUERY_MEMBER = 'QUERY_MEMBER';
export const CHECKALL_MEMBER = 'CHECKALL_MEMBER';
export const CHECK_MEMBER = 'CHECK_MEMBER';

export const FILTER_MEMBER_BY_VALUE = 'FILTER_MEMBER_BY_VALUE';

//articles related
export const QUERY_ARTICLES = 'QUERY_ARTICLES';
export const UPDATE_ARTICLES_TAG = 'UPDATE_ARTICLES_TAG';
export const QUERY_USERS = 'QUERY_USERS';
export const ARTICLE_LOADING = 'ARTICLE_LOADING';
export const USERS_LOADING = 'USERS_LOADING';

//user data
export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";


//layout
export const SET_LAYOUT_SETTINGS = "LAYOUT_SET_SETTINGS";
export const SET_DEFAULT_SETTINGS = "SET_DEFAULT_SETTINGS";

//dashboard
export const QUERY_DASHBOARDARTICLES = "QUERY_DASHBOARDARTICLES";

//tracking
export const QUERY_TRACKINGS = "QUERY_TRACKINGS";
export const TRACKING_LOADING = 'ARTICLE_LOADING';
export const QUERY_TRACKING_PURCHASES = "QUERY_TRACKING_PURCHASES";
export const SHOPPINGUSER_LOADING = "SHOPPINGUSER_LOADING";

export const QUERY_NOTIFY = "QUERY_NOTIFY";