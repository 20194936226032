import React from 'react';
import {
    withStyles,
    createMuiTheme,
    ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableToolbar from './TableToolbar.js';
import Typography from '@material-ui/core/Typography';
import * as locales from '@material-ui/core/locale';
import makeData from '../makeData';
import { Translation } from 'react-i18next';
import '@styles/table.scss';
import {
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
    useExpanded,
} from 'react-table';
import { queryUsers } from '@actions/articleAction';
import { useDispatch, useSelector } from 'react-redux';
import { USERS_LOADING } from '@actions/types';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    },
);

const inputStyle = {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
};
// This could be inlined into SubRowAsync, this this lets you reuse it across tables
// subrow element
function SubRows({
    row,
    rowProps,
    visibleColumns,
    data,
    loading,
    usersStatus,
}) {
    // error handling here :)
    const dispatch = useDispatch();
    const renderPhotoLink = React.useCallback(
        (profile, loading, usersStatus) => {
            if (!loading) {
                //console.log("SubRows render:" + usersStatus);
                const user = usersStatus.find(
                    (ele) => ele['user_id'] == profile,
                );

                if (user)
                    return (
                        <img
                            className="tableuse"
                            style={{ borderRadius: '50%' }}
                            src={user.photo}
                            alt="photo"
                            width="100"
                            height="100"
                        ></img>
                    );
            } else return profile;
        },
        [], // Tells React to memoize regardless of arguments.
    );
    //console.log("subrows:" + loading);
    //console.log(data);
    if (loading) {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                <Translation>
                    {(t, { i18n }) => <i>{t('main.loading')}</i>}
                </Translation>
            </TableCell>
        );
    }
    console.log(data);
    if (data.length) {
        return (
            <>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={2}
                ></TableCell>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Typography variant="h6" gutterBottom component="div">
                        留言回覆
                    </Typography>
                    <MaUTable size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right"></TableCell>
                                <TableCell width="100">作者</TableCell>
                                <TableCell>內容</TableCell>

                                <TableCell width="100">回覆數</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((repliesRow, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell width="100" align="right">
                                            {renderPhotoLink(
                                                repliesRow.profile,
                                                loading,
                                                usersStatus,
                                            )}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {repliesRow.author}
                                        </TableCell>
                                        <TableCell>{repliesRow.text}</TableCell>

                                        <TableCell align="right">
                                            {repliesRow.replies.length}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MaUTable>
                </TableCell>
            </>
        );
    } else {
        return <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Translation>
            {(t, { i18n }) => <i>{t('articles.no_comment')}</i>}
        </Translation>
    </TableCell>;
    }
}

export function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState(row.original.comments);
    const usersStatus = useSelector((state) => state.articleStates.users);
    const usersLoading = useSelector(
        (state) => state.articleStates.usersLoading,
    );

    const dispatch = useDispatch();
    React.useEffect(() => {
        const timer = setTimeout(() => {
            //console.log('SubRowAsync enter:' + usersLoading);
            if (usersLoading != 'succeeded') {
                let profileList = '';
                data.forEach(
                    (element) => (profileList += element['profile'] + ';'),
                );
                profileList.replace(/;\s*$/, '');
                //console.log(profileList);
                dispatch(queryUsers({ profiles: profileList }));
            } else {
                //console.log(row);
                //console.log(usersStatus);
                setLoading(false);
            }
        }, 0);
        //get all profile
        dispatch({ type: USERS_LOADING });
        return () => {
            clearTimeout(timer);
        };
    }, [usersStatus, dispatch]);

    return (
        <SubRows
            row={row}
            rowProps={rowProps}
            visibleColumns={visibleColumns}
            data={data}
            loading={loading}
            usersStatus={usersStatus}
        />
    );
}
const styles = (theme) => ({
    tableRow: {
        '&$hover:hover': {
            backgroundColor: 'blue',
        },
    },
    tableCell: {
        '$hover:hover &': {
            color: 'pink',
        },
    },
    hover: {},
});
// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <input
            style={inputStyle}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

EditableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.any.isRequired,
    }),
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }),
    column: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    updateMyData: PropTypes.func.isRequired,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
};

const EnhancedTable = ({
    columns,
    data,
    setData,
    updateMyData,
    skipPageReset,
    renderRowSubComponent,
    userID,
}) => {
    //hooks should be in the function
    const getRowId = React.useCallback((row) => {
        return row._id;
    }, []);
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        getTableBodyProps,
        rows,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter, expanded },
    } = useTable(
        {
            columns,
            data,
            getRowId,
            defaultColumn,
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,

        (hooks) => {
            hooks.allColumns.push((columns) => [
                {
                    // Make an expander cell
                    Header: () => null, // No header
                    id: 'expander', // It needs an ID
                    Cell: ({ row }) => (
                        // Use Cell to render an expander for each row.
                        // We can use the getToggleRowExpandedProps prop-getter
                        // to build the expander.
                        <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? '👇' : '👉'}
                        </span>
                    ),
                    // We can override the cell renderer with a SubCell to be used with an expanded row
                    SubCell: () => null, // No expander on an expanded row
                },
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox.  Pagination is a problem since this will select all
                    // rows even though not all rows are on the current page.  The solution should
                    // be server side pagination.  For one, the clients should not download all
                    // rows in most cases.  The client should only download data for the current page.
                    // In that case, getToggleAllRowsSelectedProps works fine.
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        },
    );
    const [locale, setLocale] = React.useState('zhTW');
    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(Number(event.target.value));
    };

    const removeByIndexs = (array, indexs) =>
        array.filter((_, i) => !indexs.includes(i));

    const deleteUserHandler = (event) => {
        const newData = removeByIndexs(
            data,
            Object.keys(selectedRowIds).map((x) => parseInt(x, 10)),
        );
        setData(newData);
    };

    const addUserHandler = (user) => {
        const newData = data.concat([user]);
        setData(newData);
    };

    const updateTagHandler = (user) => {
        console.log('updateTagHandler');
        /*
        const newData = data.concat([user])
        setData(newData)*/
    };

    const selectedTags = () => {
        let tagSet = new Set();
        selectedFlatRows.forEach((element) =>
            element.original.articleTags
                ? element.original.articleTags.map((element) =>
                      element ? tagSet.add(element) : '',
                  )
                : '',
        );
        console.log('tag:' + tagSet.size);
        return tagSet;
    };

    const [open, setOpen] = React.useState(false);
    const theme = createMuiTheme({
        overrides: {
            MuiTypography: {
                h5: {
                    color: '#0000FF',
                },
            },
            MuiTableCell: {
                head: {
                    fontWeight: 'bolder',
                    color: '#0000FF',
                    fontSize: '16px',
                },
            },
        },
    });
    // Render the UI for your table
    return (
        <>
            <TableContainer>
                <TableToolbar
                    numSelected={Object.keys(selectedRowIds).length}
                    selectedRowIds={selectedRowIds}
                    originalTag={selectedTags}
                    userID={userID}
                    deleteUserHandler={deleteUserHandler}
                    addUserHandler={addUserHandler}
                    updateTagHandler={updateTagHandler}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                />
                <MuiThemeProvider theme={theme}>
                    <MaUTable
                        {...getTableProps()}
                        options={{
                            headerStyle: { backgroundColor: 'red' },
                        }}
                    >
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column) => (
                                        <TableCell
                                            {...(column.id === 'selection'
                                                ? column.getHeaderProps()
                                                : column.getHeaderProps(
                                                      column.getSortByToggleProps(),
                                                  ))}
                                        >
                                            {column.render('Header')}
                                            {column.id !== 'selection' ? (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    // react-table has a unsorted state which is not treated here
                                                    direction={
                                                        column.isSortedDesc
                                                            ? 'desc'
                                                            : 'asc'
                                                    }
                                                />
                                            ) : null}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {page.map((row, i) => {
                                prepareRow(row);

                                const rowProps = row.getRowProps();
                                return (
                                    <React.Fragment key={rowProps.key}>
                                        <TableRow {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <TableCell
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                        <TableRow>
                                            {row.isExpanded &&
                                                renderRowSubComponent({
                                                    row,
                                                    rowProps,
                                                    visibleColumns,
                                                })}
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        5,
                                        10,
                                        25,
                                        { label: '全部', value: data.length },
                                    ]}
                                    labelRowsPerPage="每頁顯示"
                                    labelDisplayedRows={(from = page) =>
                                        `${from.from}-${
                                            from.to === -1
                                                ? from.count
                                                : from.to
                                        } 的 ${from.count}`
                                    }
                                    colSpan={7}
                                    count={data.length}
                                    rowsPerPage={pageSize}
                                    page={pageIndex}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': '每頁有',
                                        },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </MaUTable>
                </MuiThemeProvider>
            </TableContainer>
        </>
    );
};

EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    updateMyData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
    userID: PropTypes.string,
};

export default withStyles(styles)(EnhancedTable);
