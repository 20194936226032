/* eslint-disable import/no-anonymous-default-export */
/* jshint esversion: 9*/
import { QUERY_ARTICLES, ARTICLE_LOADING, UPDATE_ARTICLES_TAG, QUERY_USERS, USERS_LOADING } from '@actions/types';

const initialState = {
    articles: [],
    users: [],
    status: 'idle',
    error: null,
    usersLoading: 'idle'
};

export default function(state = initialState, action) {
    switch (action.type){
        case QUERY_ARTICLES:
            return {
                ...state,
                articles: action.payload,
                status: 'succeeded'
            };
        case UPDATE_ARTICLES_TAG:
            console.log(action.payload);
            console.log(state.articles);
            return {
                ...state,
                articles: { data: state.articles.data.map( article =>  action.payload.rows.indexOf(article._id)!==-1 ?
                    {...article, articleTags: action.payload.tags.split(';')}: article)},
                status: 'succeeded'
            };
        case ARTICLE_LOADING:
            return {
                ...state,
                articles: action.payload,
                status: 'loading'
            };  
        case QUERY_USERS:
            //remove new users from old users
            //cascade new users
            let newData = action.payload;
            const oldUsers = state.users.filter(user => !newData.some(newUser => newUser._id === user._id));
            return {
                ...state,
                users: oldUsers.concat(action.payload),
                usersLoading: 'succeeded'
            };
        case USERS_LOADING:
            return {
                ...state,
                usersLoading: 'loading'
            };
        default:
            return {...state};
    }
}