module.exports = {
    LOCAL_DEBUG: process.env.REACT_APP_LOCAL_DEBUG,
    LOCAL_DEBUG_USER: JSON.parse(process.env.REACT_APP_LOCAL_DEBUG_USER),
    LOCAL_DEBUG_MEMBER: JSON.parse(process.env.REACT_APP_LOCAL_DEBUG_MEMBER),
    LOCAL_DEBUG_TOKEN: process.env.REACT_APP_LOCAL_DEBUG_TOKEN,
    LOCAL_DEBUG_GROUP: process.env.REACT_APP_LOCAL_DEBUG_GROUP,
    //setting
    API_ROOT:
        process.env.REACT_APP_LOCAL_DEBUG == 'true'
            ? 'http://localhost:8084/api/'
            : 'https://prod-dazzling.azurewebsites.net/api/',
    DEMO_ID: '306909817697227A',
};
