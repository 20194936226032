import React from 'react';
import FacebookLoginWithButton from 'react-facebook-login';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import history from '@src/history.js';
import config from '../Config';
//google login
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

const LoginCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffffe5;
    height: 100%;
    width: 50vh;
    border-radius: 20px;
    margin: 50px;
    @media (max-width: 788px) {
        margin: 0px;
        width: 100%;
    }
`;

const Title = styled.div`
    color: #6666ff;
    font-size: 30px;
    font-weight: bold;
`;

const SubTitle = styled.div`
    color: #6666ff;
    font-size: 20px;
    font-weight: 600;
`;

const Wrapper = styled.div`
    margin: 40px 40px 40px 40px;
    border-radius: 50px;
`;

const componentClicked = () => {
    // Query Account Info, get groupID
};

const LoginButton = ({ facebookResponse }) => (
    <FacebookLoginWithButton
        appId="2414851492097790"
        // autoLoad
        fields="name,email,picture"
        onClick={componentClicked}
        callback={facebookResponse}
        icon="fa-facebook"
    />
);

const MainScreen = ({ user }) => (
    <>
        <Redirect to="/" />
    </>
);

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false,
        };
        this.clientId =
            '579678893251-iep74mdj7ms5fjqsp4qvn2grv2i7d96l.apps.googleusercontent.com';
        this.googleResponse = this.googleResponse.bind(this);
    }

    componentDidMount = () => {
        const initClient = () => {
            gapi.client.init({
                clientId: this.clientId,
                scope: '',
            });
        };
        gapi.load('client:auth2', initClient);
    };

    onSuccess = (response) => {
        console.log('success:', response);
        this.googleResponse(response, this.doneLogin);
    };
    onFailure = (err) => {
        console.log('failed:', err);
    };

    onValueChange = (e) => {
        sessionStorage.setItem('groupID', e.target.value);
    };

    googleResponse = (resp) => {
        console.log(resp);

        sessionStorage.setItem('new_google_id', resp.googleId);
        this.setState({ ...this.state, user: resp });
        const groupID = sessionStorage.getItem('groupID');
        resp.groups = [{ groupID: groupID }];

        let accountUrl = `${config.API_ROOT}register`;
        //let accountUrl = 'http://localhost:8082/api/account'

        fetch(accountUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
            },
            body: JSON.stringify(resp),
        })
            .then((resp) =>
                history.push({
                    pathname: '/',
                }),
            )
            .catch((error) => console.error('Error:', error));
    };

    facebookResponse = (resp) => {
        console.log(resp);

        sessionStorage.setItem('new_fb_id', resp.id);
        this.setState({ ...this.state, user: resp });
        const groupID = sessionStorage.getItem('groupID');
        resp.groups = [{ groupID: groupID }];

        let accountUrl = `${config.API_ROOT}register`;
        //let accountUrl = 'http://localhost:8082/api/account'

        fetch(accountUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: '*/*',
            },
            body: JSON.stringify(resp),
        })
            .then((resp) =>
                history.push({
                    pathname: '/',
                }),
            )
            .catch((error) => console.error('Error:', error));
    };

    // TODO: update web ui with user info
    // TODO: login with JWT to update user token, with JWT db storage in unnecessary.
    // TODO: save barrer token in local storage

    render() {
        return (
            <div
                style={{
                    backgroundColor: '#EEEEEE',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {this.state.user ? (
                    <MainScreen user={this.state.user} />
                ) : (
                    <LoginCard>
                        <div />
                        <Title>社團管家公</Title>
                        <SubTitle>請輸入你社團編號</SubTitle>
                        <input
                            onChange={this.onValueChange}
                            style={{ width: '80%', height: '40px' }}
                            id="groupID"
                            type="text"
                            placeholder="群組編號"
                        ></input>
                        <div>
                            <GoogleLogin
                                clientId={this.clientId}
                                buttonText="Register with Google"
                                onSuccess={this.onSuccess}
                                onFailure={this.onFailure}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={false}
                            />
                        </div>
                        <div />
                    </LoginCard>
                )}
            </div>
        );
    }
}

const wait = (ms) => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
};

export default Register;
