/* jshint esversion: 8 */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ConnectedMemberList from './memberList';
import MemberArticles from '@components/MemberArticles';
/*
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDown from '@material-ui/icons/ThumbDown';
import AppBar from '@material-ui/core/AppBar';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
*/
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
}));

export default function TabView() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    return (
        <Paper className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                <Tab
                    label="會員名單"
                    icon={<PersonPinIcon />}
                    {...a11yProps(0)}
                    to="/one"
                />
                <Tab
                    label="文章列表"
                    icon={<ThumbUp />}
                    {...a11yProps(1)}
                    to="/two"
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ConnectedMemberList passedFunction={() => setValue(1)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MemberArticles show_total={false} />
            </TabPanel>
        </Paper>
    );
}
