import Config from '@src/Config';
const API_ROOT = Config.API_ROOT;

let user = JSON.parse(sessionStorage.getItem('usersession'));
let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  

export const queryDashboardArticles = async (searchParams) => {
    // Query Member List
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  
    console.log('fetchDashboardArticles: queryDashboardArticles');
    console.log(searchParams);
    let searchUrl = encodeURI(
        API_ROOT + `group/${selectGroup.groupID}/dashboard/dashboardInteractionStatistics?` + 
        'interval=' + searchParams.params.interval + '&mode=' + searchParams.params.mode
        /*
        API_ROOT +
        `group/${selectGroup.groupAlias}/dashboard/dashboardarticles?` +
            'begin=' +
            searchParams.params.begin +
            '&end=' +
            searchParams.params.end,*/
    );

    console.log('fetchDashboardArticles:' + searchUrl);
    //db.getCollection('helpbuys').find({'category':{$regex : "^((?!all).)*$"},'interactions':{$elemMatch:{'name':{$regex : "邱以慈"}}}})
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardMembers = async (searchParams) => {
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup')); 
    console.log('fetchDashboardMembers');
    console.log(searchParams);
    console.log('fetchDashboardMembers: '+ selectGroup );
    let searchUrl = encodeURI(
        API_ROOT +
            `group/${selectGroup.groupID}/dashboard/dashboardmembers?` +
            'begin=' +
            searchParams.params.begin,
    );
    console.log(searchUrl);
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    console.log('fetchDashboardMembers middle');
    let ret = await response.json();
    console.log('fetchDashboardMembers end');
    return ret;
};

export const queryDashboardInteractions = async (searchParams) => {
    console.log('queryDashboardInteractions');
    console.log(searchParams);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  
    let searchUrl = encodeURI(
        API_ROOT +
            `group/${selectGroup.groupID}/dashboard/dashboardinteractions?` +
            'begin=' +
            searchParams.params.begin,
    );
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardAllMembers = async (searchParams) => {
    console.log('queryDashboardAllMembers');
    console.log(searchParams);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  
    let searchUrl = encodeURI(
        API_ROOT +
            `group/${selectGroup.groupID}/members/statistics?` +
            'mode=months'
    );

    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardActiveMembers = async (searchParams) => {
    console.log('queryDashboardActiveMembers');
    console.log(searchParams);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  

    let searchUrl = encodeURI(
        API_ROOT +
        `group/${selectGroup.groupID}/dashboard/dashboardactivemembers?` +
            'begin=' +
            searchParams.params.begin,
    );
    console.log(searchUrl);
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardArticlesType = async (searchParams) => {
    console.log('queryDashboardArticlesType');
    console.log(searchParams);

    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));

    let searchUrl = encodeURI(
        API_ROOT +
            `group/${selectGroup.groupID}/dashboard/dashboardarticlestype?` +
            'begin=' +
            searchParams.params.begin,
    );
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardAllArticles = async (searchParams) => {
    console.log('queryDashboardAllArticles');
    console.log(sessionStorage.getItem('selectedGroup'));
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));
    console.log(selectGroup);
    let searchUrl = encodeURI(
        API_ROOT +
            `group/${selectGroup.groupID}/dashboard/dashboardallarticles?mode=months&` +
            'begin=' +
            searchParams.params.begin,
    );
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardTotalArticles = async (searchParams) => {
    console.log('queryDashboardTotalArticles');
    console.log(searchParams);
    let user = JSON.parse(sessionStorage.getItem('usersession'));
    let selectGroup = JSON.parse(sessionStorage.getItem('selectedGroup'));  
    let searchUrl = encodeURI(
        API_ROOT +
        `group/${selectGroup.groupID}/dashboard/dashboardtotalarticles?` +
            'begin=' +
            searchParams.params.begin +
            '&end=' +
            searchParams.params.end,
    );
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    let ret = await response.json();

    return ret;
};

export const queryDashboardMyshopperMembers = async (searchParams) => {
    console.log('fetchDashboardAllMembers: queryDashboardMyshopperMembers');
    console.log('fetchDashboardAllMembers:' + searchParams); 
    let searchUrl = encodeURI(
        API_ROOT +
            'my_shopper/users/members/statistics?' +
            'mode=months'
    );
    console.log('fetchDashboardAllMembers:' + searchUrl);
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    console.log('fetchDashboardAllMembers: middle');
    let ret = await response.json();

    return ret;
};

export const queryDashboardMyshopperTrackers = async (searchParams) => {
    console.log('fetchDashboardTrackers: queryDashboardMyshopperTrackers');
    console.log('fetchDashboardTrackers:' + searchParams); 
    let searchUrl = encodeURI(
        API_ROOT +
            '/my_shopper/tracking?' +
            'mode=months'
    );
    console.log('fetchDashboardTrackers:' + searchUrl);
    let response = await fetch(searchUrl, {
        method: 'GET',
        headers: {
            'Accept-Encoding': 'gzip, deflate, br',
            Authorization: `berear-${user.token}`,
            Accept: '*/*',
        },
    });
    console.log('fetchDashboardTrackers: finished');
    let ret = await response.json();

    return ret;
};
