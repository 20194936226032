import React, { useEffect } from 'react';
import { Grid, Card, Icon, Fab } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchDashboardMembers,
    fetchDashboardActiveMembers,
} from '@reducers/dashboardSlice';
import { format } from 'date-fns';
import { Translation, useTranslation } from 'react-i18next';
import { fetchDashboardTotalArticles } from 'redux/reducers/dashboardSlice';

const StatCards2 = () => {
    const dispatch = useDispatch();

    const allMembers = useSelector((state) => state.dashboardAllMembers);
    const totalArticles = useSelector((state) => state.dashboardTotalArticles);
    useEffect(() => {
        
        console.log('fetchDashboardtotalArticles:' + totalArticles.status);
        if (totalArticles.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setFullYear(d_end.getFullYear() - 10);
            dispatch(
                fetchDashboardTotalArticles({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        } else if (totalArticles.status === 'succeed') {
            console.log('totalArticles:');
            console.log(totalArticles.entities);
        }
    }, [totalArticles, dispatch]);

    let activeMembersCnt, activeMembersGrowth;
    const articles = useSelector((state) => state.dashboardArticles);
    if (allMembers.status === 'succeed') {
        console.log(allMembers);
        if (articles.ids.length > 0) {
            activeMembersCnt =
                articles.entities[articles.ids[articles.ids.length - 1]]
                    .activeMembers;
            let prevActiveMembersCnt =
                articles.entities[articles.ids[articles.ids.length - 2]]
                    .activeMembers;
            activeMembersGrowth =
                ((activeMembersCnt - prevActiveMembersCnt) /
                    prevActiveMembersCnt) *
                100;
            activeMembersGrowth =
                activeMembersGrowth > 0
                    ? '+' + activeMembersGrowth.toString().split('.')[0]
                    : '-' + activeMembersGrowth.toString().split('.')[0];
        }
    }

    let allArticlesGrowth;
    const allArticles = useSelector((state) => state.dashboardAllArticles);
    if (allArticles.status === 'succeed') {
        console.log(allArticles);
        if (allArticles.ids.length > 0) {
            let curArticles =
                allArticles.entities[
                    allArticles.ids[allArticles.ids.length - 1]
                ].count;
            let prevArticles =
                allArticles.entities[
                    allArticles.ids[allArticles.ids.length - 2]
                ].count;
            allArticlesGrowth =
                ((curArticles - prevArticles) / prevArticles) * 100;
            allArticlesGrowth =
                allArticlesGrowth > 0
                    ? '+' + allArticlesGrowth.toString().split('.')[0]
                    : allArticlesGrowth.toString().split('.')[0];
        }
    }

    return (
        <Grid container spacing={3} className="mb-6">
            <Grid item xs={12} md={6}>
                <Card elevation={3} className="p-4">
                    <div className="flex items-center">
                        <Fab
                            size="medium"
                            className="bg-light-green circle-44 box-shadow-none"
                        >
                            <Icon className="text-green">trending_up</Icon>
                        </Fab>
                        <h5 className="font-medium text-green m-0 ml-3">
                            <big
                                className="text-green"
                                style={{ fontSize: '20px' }}
                            >
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i>
                                            {t(
                                                'dashboard.statcard.activemembers',
                                            )}
                                        </i>
                                    )}
                                </Translation>
                            </big>
                        </h5>
                    </div>
                    <div className="pt-4 d-flex flex-row items-center">
                        <h2 className="m-0 text-primary flex-grow-1">
                            {activeMembersCnt}
                        </h2>
                        <div className="ml-3 small-circle bg-green text-white">
                            <Icon className="small-icon">expand_less</Icon>
                        </div>
                        <span className="text-13 text-green ml-1">
                            {' '}
                            ({activeMembersGrowth}%)
                        </span>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card elevation={3} className="p-4">
                    <div className="flex items-center">
                        <Fab
                            size="medium"
                            className="bg-light-error circle-44 box-shadow-none overflow-hidden"
                        >
                            <Icon className="text-error">star_outline</Icon>
                        </Fab>
                        <h5 className="font-medium text-error m-0 ml-3">
                            <big
                                className="text-red"
                                style={{ fontSize: '20px' }}
                            >
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i>
                                            {t(
                                                'dashboard.statcard.totalarticles',
                                            )}
                                        </i>
                                    )}
                                </Translation>
                            </big>
                        </h5>
                    </div>
                    <div className="pt-4 d-flex flex-row items-center">
                        <h2 className="m-0 text-primary flex-grow-1 font-medium">
                            {totalArticles.status === 'succeed'
                                ? totalArticles.ids.length !== 0
                                    ? totalArticles.entities[0].myCount
                                    : '0'
                                : 'loading'}
                        </h2>
                        <div className="ml-3 small-circle bg-error text-white">
                            <Icon className="small-icon">expand_less</Icon>
                        </div>
                        <span className="text-13 text-error ml-1">
                            ({allArticlesGrowth}%)
                        </span>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
};

export default StatCards2;
