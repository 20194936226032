import { SEARCH_SHOPEE, QUERY_MEMBER, FILTER_MEMBER_BY_VALUE, CHECKALL_MEMBER, CHECK_MEMBER } from '@actions/types';

const initialState = {
    menu: [],
    appliedFilters: [],
    originalData: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_SHOPEE:
            return {
                ...state,
                stores: action.payload,
            };
        case QUERY_MEMBER:
            return {
                ...state,
                stores: action.payload,
            };
        case CHECKALL_MEMBER:
            return {
                ...state,
                stores:action.payload
            };
        case CHECK_MEMBER:
            return {
                ...state,
                stores:action.payload
            };            
        case FILTER_MEMBER_BY_VALUE:
            //clone the state
            let newState = {...state};
            let value = action.payload.value;
            console.log(state);
            let originalData = [];
            if(state.originalData.length == 0)
                originalData = [...state.stores.data];
            else
                originalData = state.originalData;
            let filteredValues = originalData.filter((store) => {
                //return any product whose name or designer contains the input box string

                return store.userName
                    .toLowerCase()
                    .includes(value.toLowerCase());
            });

            let appliedFilters = newState.appliedFilters;
            
            //if the value from the input box is not empty
            if (value) {
                //check if the filter already exists in the tracking array
                console.log(appliedFilters);
                let index = appliedFilters.indexOf(FILTER_MEMBER_BY_VALUE);
                if (index === -1)
                    //if it doesn’t, add it.
                    appliedFilters = [...appliedFilters,FILTER_MEMBER_BY_VALUE];
                //change the filtered products to reflect the change
                //newState.filteredProducts = filteredValues;
            } else {
                //if the value is empty, we can assume everything has been erased
                let index = appliedFilters.indexOf(FILTER_MEMBER_BY_VALUE);
                //in that case, remove the current filter
                appliedFilters = [...appliedFilters];
                appliedFilters.splice(index, 1);
                if (appliedFilters.length === 0) {
                    //if there are no filters applied, reset the products to normal.
                    //filteredValues = originalData;
                }
            }
            return {
                ...state,
                originalData:originalData,
                appliedFilters:appliedFilters,
                stores: { ...state.stores, data: filteredValues,  },
            };
        default:
            return { ...state };
    }
}
