/* jshint esversion: 9 */
import { QUERY_DASHBOARDARTICLES } from '@actions/types';
import { queryDashboardArticles, queryDashboardMembers, queryDashboardInteractions, 
    queryDashboardArticlesType, queryDashboardActiveMembers, queryDashboardAllMembers, queryDashboardTotalArticles, queryDashboardAllArticles, 
    queryDashboardMyshopperMembers, queryDashboardMyshopperTrackers } from '@actions/dashboardAction';
import {
    createSlice,
    createSelector,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';

export const fetchDashboardArticles = createAsyncThunk(
    'dashboardArticles/fetchDashboardArticles',
    async (params) => {
        const response = await queryDashboardArticles({ params });
        console.log('fetchDashboardArticles:');
        console.log(response);
        return response.InteractionStatistics;
    },
);

const dashboardArticlesAdapter = createEntityAdapter();

const initialState = dashboardArticlesAdapter.getInitialState({
    status: 'idle',
});

const dashboardSlice = createSlice({
    name: 'dashboardArticles',
    initialState,
    reducers: {
        articlesSelected: {
            reducer(state, action) {
                const { color, todoId } = action.payload;
                state.entities[todoId].color = color;
            },
            prepare(todoId, color) {
                return {
                    payload: { todoId, color },
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardArticles.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardArticles.fulfilled, (state, action) => {
                //console.log(action.payload);
                console.log(state);
                dashboardArticlesAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});
/*
export const {
    selectAll: selectDashboardArticles,
  } = dashboardArticlesAdapter.getSelectors((state) => state.dashboardArticles);

export const selectArticles = createSelector(
    // First, pass one or more "input selector" functions:
    selectDashboardArticles,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (articles) => articles.map((article) => article)
);*/
export const fetchDashboardMembers = createAsyncThunk(
    'dasboardMembers/fetchDashboardMembers',
    async (params) => {
        const response = await queryDashboardMembers({ params });
        console.log('fetchDashboardMembers');
        console.log(response);
        return response.dashboardMembers;
    },
);

const dashboardMembersAdapter = createEntityAdapter();

const dasboardMemberInitialState = dashboardMembersAdapter.getInitialState({
    status: 'idle',
});
export const dashboardMembers = createSlice({
    name: 'dasboardMembers',
    initialState: dasboardMemberInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardMembers.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardMembers.fulfilled, (state, action) => {
                console.log('fetchDashboardMembers success');
                dashboardMembersAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});


//dashboard interaction
export const fetchDashboardInteractions = createAsyncThunk(
    'dasboardInteractions/fetchDashboardInteractions',
    async (params) => {
        const response = await queryDashboardInteractions({ params });
        console.log('fetchDashboardInteractions');
        console.log(response);
        return response.dashboardInteractions;
    },
);

const dashboardInteractionsAdapter = createEntityAdapter();

const dasboardInteractionsInitialState = dashboardInteractionsAdapter.getInitialState({
    status: 'idle',
});
export const dashboardInteractions= createSlice({
    name: 'dasboardInteractions',
    initialState: dasboardInteractionsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardInteractions.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardInteractions.fulfilled, (state, action) => {
                console.log('fetchDashboardInteractions success');
                dashboardInteractionsAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});

//dashboard activemembers
export const fetchDashboardActiveMembers = createAsyncThunk(
    'dasboardInteractions/fetchDashboardActiveMembers',
    async (params) => {
        const response = await queryDashboardActiveMembers({ params });
        console.log('fetchDashboardActiveMembers');
        console.log(response);
        return response.dashboardActiveMembers;
    },
);

const dashboardActiveMembersAdapter = createEntityAdapter();

const dasboardActiveMembersInitialState = dashboardActiveMembersAdapter.getInitialState({
    status: 'idle',
});
export const dashboardActiveMembers= createSlice({
    name: 'dashboardActiveMembers',
    initialState: dasboardActiveMembersInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardActiveMembers.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardActiveMembers.fulfilled, (state, action) => {
                console.log('fetchDashboardActiveMembers success');
                dashboardActiveMembersAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});

//dashboard articles type
export const fetchDashboardArticlesType = createAsyncThunk(
    'dasboardArticlesType/fetchDashboardArticlesType',
    async (params) => {
        const response = await queryDashboardArticlesType({ params });
        console.log('fetchDashboardArticlesType');
        console.log(response);
        return response.dashboardArticlesType;
    },
);

const dashboardArticlesTypeAdapter = createEntityAdapter();

const dasboardArticlesTypeInitialState = dashboardArticlesTypeAdapter.getInitialState({
    status: 'idle',
});
export const dashboardArticlesType= createSlice({
    name: 'dasboardArticlesType',
    initialState: dasboardArticlesTypeInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardArticlesType.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardArticlesType.fulfilled, (state, action) => {
                console.log('fetchDashboardArticlesType success'+ action);
                dashboardArticlesTypeAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});


//dashboard all member
export const fetchDashboardAllMembers = createAsyncThunk(
    'dasboardAllMember/fetchDashboardAllMembers',
    async (params) => {
        //const response = await queryDashboardAllMembers({ params });
        console.log('fetchDashboardAllMembers:');
        const response = await queryDashboardMyshopperMembers({ params });
        
        console.log(response.statistics);
        return response.statistics;
    },
);

const dashboardAllMembersTypeAdapter = createEntityAdapter();

const dasboardAllMembersInitialState = dashboardAllMembersTypeAdapter.getInitialState({
    status: 'idle',
});
export const dashboardAllMembers= createSlice({
    name: 'dashboardAllMembers',
    initialState: dasboardAllMembersInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardAllMembers.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardAllMembers.fulfilled, (state, action) => {
                console.log('fetchDashboardAllMembers: success'+ action);
                dashboardAllMembersTypeAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});


//dashboard total articles
export const fetchDashboardTotalArticles = createAsyncThunk(
    'fetchDashboardTotalArticles',
    async (params) => {
        const response = await queryDashboardTotalArticles({ params });
        console.log('fetchDashboardTotalArticles');
        console.log(response.dashboardtotalArticles);
        return response.dashboardtotalArticles;
    },
);

const dashboardTotalArticlesAdapter = createEntityAdapter();

const dasboardTotalArticlesInitialState = dashboardTotalArticlesAdapter.getInitialState({
    status: 'idle',
});
export const dashboardTotalArticles = createSlice({
    name: 'dashboardTotalArticles',
    initialState: dasboardTotalArticlesInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardTotalArticles.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardTotalArticles.fulfilled, (state, action) => {
                console.log('fetchDashboardTotalArticles success'+ action);
                dashboardTotalArticlesAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});

//dashboard total articles
export const fetchDashboardAllArticles = createAsyncThunk(
    'fetchDashboardAllArticles',
    async (params) => {
        const response = await queryDashboardAllArticles({ params });
        console.log('fetchDashboardAllArticles:');
        console.log(response.dashboardallArticles);
        return response.dashboardallArticles;
    },
);

const dashboardAllArticlesAdapter = createEntityAdapter();

const dasboardAllArticlesInitialState = dashboardAllArticlesAdapter.getInitialState({
    status: 'idle',
});
export const dashboardAllArticles = createSlice({
    name: 'dashboardAllArticles',
    initialState: dasboardAllArticlesInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardAllArticles.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardAllArticles.fulfilled, (state, action) => {
                console.log('fetchDashboardAllArticles success:'+ action);
                dashboardAllArticlesAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});


//dashboard total trackings
export const fetchDashboardTrackings = createAsyncThunk(
    'fetchDashboardTrackings',
    async (params) => {
        const response = await queryDashboardMyshopperTrackers({ params });
        console.log('fetchDashboardTrackings:');
        console.log(response.statistics);
        return response.statistics;
    },
);

const dashboardTrackingsAdapter = createEntityAdapter();

const dasboardTrackingsInitialState = dashboardTrackingsAdapter.getInitialState({
    status: 'idle',
});
export const dashboardTrackings = createSlice({
    name: 'dashboardTrackings',
    initialState: dasboardTrackingsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardTrackings.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchDashboardTrackings.fulfilled, (state, action) => {
                console.log('fetchDashboardTrackings success:'+ action);
                dashboardTrackingsAdapter.setAll(state, action.payload);
                state.status = 'succeed';
            });
    },
});

export const { articlesSelected } = dashboardSlice.actions;

export default dashboardSlice.reducer;
