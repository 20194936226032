import React from 'react';
import GridExample from './AccountsManagement';
import { authRoles } from '../../auth/authRoles';

const UserSettingRoute = [
    {
        path: '/UserManagement',
        component: GridExample,
        sidebarName: 'UserManagement',
        exact: true,
        auth: authRoles.sa,
    },
];

export default UserSettingRoute;
