/* jshint esversion: 8 */
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import MiniDrawer from './views/dashboard';
import store from './store.js';
import routes from './router.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { default as LuxiLayouts } from './LuxiLayouts';
import Auth from './auth/Auth';
import AuthGuard from './auth/AuthGuard';
import history from 'history.js';
import AppContext from 'AppContext';

i18n.changeLanguage('zh-TW');
ReactDOM.render(
    <React.StrictMode>
        <AppContext.Provider value={{ routes }}>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <Auth>
                        <Router history={history}>
                            <AuthGuard>
                                <LuxiLayouts />
                            </AuthGuard>
                        </Router>
                    </Auth>
                </I18nextProvider>
            </Provider>
        </AppContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
