import React, { useState, Fragment } from 'react';
import { default as Routes } from '../../router.js';
import { NavLink, withRouter, Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    MenuList,
    MenuItem,
    ListItemText,
    CssBaseline,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    Divider,
} from '@material-ui/core';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { spacing, typography } from '@material-ui/system';
import { blue600 } from '@material-ui/core/colors/blue';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import TrackIcon from '@material-ui/icons/TrackChanges';
import GroupIcon from '@material-ui/icons/Group';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ListIcon from '@material-ui/icons/List';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Switch, Route } from 'react-router-dom';
import routes from '../../router.js';
import Config from '@src/Config';
import history from 'history.js';
import store from '../../store';
import { authRoles } from '../../auth/authRoles';
import { useTranslation } from 'react-i18next';

let user = JSON.parse(sessionStorage.getItem('usersession'));
let timenow = Math.floor(Date.now() / 1000);
let expiration = parseInt(user ? user.expiration : 0);

if (!user || !user.token) {
    history.push({
        pathname: '/',
    });
} else if (timenow - expiration > 0) {
    history.push({
        pathname: '/',
    });
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        backgroundColor: 'rgb(247, 232, 241, .2)',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'rgb(247, 232, 241, .2)',
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    lefttoolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundImage: 'url(' + require('../../images/material_bg.png') + ')',
        padding: theme.spacing(0, 1),
        height: 90,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        height: '90px',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
}));
const drawerWidth = 240;

const NavigationBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const settings = useSelector((state) => state.LayoutSetting.settings);
    const { t, i18n } = useTranslation();

    console.log(settings);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const onItemClick = (title) => () => {};
    let user = JSON.parse(sessionStorage.getItem('usersession'));

    const styles = {
        logo: {
            cursor: 'pointer',
            fontSize: 22,
            color: typography.textFullWhite,
            lineHeight: `${spacing.desktopKeylineIncrement}px`,
            fontWeight: typography.fontWeightLight,
            backgroundColor: blue600,
            paddingLeft: 40,
            height: 56,
        },
        menuItem: {
            color: { color: 'white' },
            fontSize: 14,
        },
        avatar: {
            div: {
                padding: '15px 0 20px 15px',
                backgroundImage:
                    'url(' + require('../../images/material_bg.png') + ')',
                height: 45,
            },
            icon: {
                float: 'left',
                display: 'block',
                marginRight: 5,
                boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)',
            },
            span: {
                paddingTop: 12,
                display: 'block',
                color: 'white',
                fontWeight: 300,
                textShadow: '1px 1px #444',
            },
        },
    };
    /////////
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    const activeRoute = (routeName) => {
        return props.location.pathname === routeName ? true : false;
    };

    const logoutUser = () => {
        //jwtAuthService.logout();

        sessionStorage.removeItem('usersession');
        sessionStorage.removeItem('selectedGroup');
        //trigger reset redux state
        store.dispatch({ type: 'USER_LOGOUT' });
        history.push({
            pathname: '/',
        });
    };

    return (
        <div className={classes.root}>
            {settings.show && (
                <>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        <Toolbar
                            style={{ height: ' 90px', background: '#6fafeb' }}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer(true)}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <nav>
                                <Typography
                                    component={'span'}
                                    variant="h6"
                                    noWrap
                                >
                                    會員大數據
                                </Typography>
                            </nav>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <div className={classes.lefttoolbar}>
                            <Avatar
                                src={
                                    user && user.profileObj
                                        ? user.profileObj.imageUrl
                                        : ''
                                }
                                size={50}
                                style={styles.avatar.icon}
                            />
                            <span style={styles.avatar.span}>
                                {user && user.profileObj ? user.profileObj.name : ''}
                            </span>
                            <IconButton onClick={toggleDrawer(false)}>
                                {theme.direction === 'rtl' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <ChevronLeftIcon />
                                )}
                            </IconButton>
                        </div>
                        <List>
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'Analytics',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/dashboard/analytics"
                                onClick={onItemClick('Home')}
                            >
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText>整體報表</ListItemText>
                            </ListItem>);
                        })()}
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'MemberView',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/MemberView"
                                onClick={onItemClick('紛絲名單')}
                            >
                                <ListItemIcon>
                                    <AccountBalanceIcon />
                                </ListItemIcon>
                                <ListItemText>紛絲名單</ListItemText>
                            </ListItem>);
                        })()}
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'MemberArticles',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/MemberArticles"
                                onClick={onItemClick('貼文分析')}
                            >
                                <ListItemIcon>
                                    <PostAddIcon />
                                </ListItemIcon>
                                <ListItemText>貼文分析</ListItemText>
                            </ListItem>);
                        })()}
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'TrackingGoods',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/TrackingGoods"
                                onClick={onItemClick('商品追蹤')}
                            >
                                <ListItemIcon>
                                    <TrackIcon />
                                </ListItemIcon>
                                <ListItemText>MyShopper商品追蹤</ListItemText>
                            </ListItem>);
                        })()}
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'MyshopperUser',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/MyshopperUser"
                                onClick={onItemClick('MyShopper使用者')}
                            >
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText>MyShopper使用者</ListItemText>
                            </ListItem>);
                        })()}
                        {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'MyshopperNotifyRecord',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                            <ListItem
                                button
                                component={Link}
                                to="/MyshopperNotifyRecord"
                                onClick={onItemClick('Notify通知紀錄')}
                            >
                                <ListItemIcon>
                                    <NotificationsActiveIcon />
                                </ListItemIcon>
                                <ListItemText>MyShopper通知紀錄</ListItemText>
                            </ListItem>);
                        })()}
                            {false && (
                                <>
                                    <ListItem
                                        button
                                        component={Link}
                                        to="/dashboard"
                                        onClick={onItemClick('訊息推播')}
                                    >
                                        <ListItemIcon>
                                            <RateReviewIcon />
                                        </ListItemIcon>
                                        <ListItemText>訊息推播</ListItemText>
                                    </ListItem>
                                    <ListItem
                                        display="none"
                                        button
                                        component={Link}
                                        to="/dashboard/analytics"
                                        onClick={onItemClick('貼文標籤')}
                                    >
                                        <ListItemIcon>
                                            <LoyaltyIcon />
                                        </ListItemIcon>
                                        <ListItemText>貼文標籤</ListItemText>
                                    </ListItem>
                                </>
                            )}
                        </List>
                        <Divider />
                        <List>
                            {(() => {
                                const matched = Routes.find(
                                    (r) => r.sidebarName === 'MenuSetting',
                                );
                                if (user && matched.auth.includes(user.role))
                                    return (
                                        <ListItem
                                            button
                                            component={Link}
                                            to="/MenuDesign"
                                            onClick={onItemClick('設定')}
                                        >
                                            <ListItemIcon>
                                                <ListIcon />
                                            </ListItemIcon>
                                            <ListItemText>設定</ListItemText>
                                        </ListItem>
                                    );
                            })()}
                            {['UserManagement', 'RoleList'].map(
                                (text, index) => {
                                    const matched = Routes.find(
                                        (r) => r.sidebarName === text,
                                    );
                                    if (
                                        user &&
                                        matched.auth.includes(user.role)
                                    )
                                        return (
                                            <ListItem
                                                button
                                                component={Link}
                                                to={text}
                                                onClick={onItemClick(text)}
                                            >
                                                <ListItemIcon>
                                                    {index % 2 === 0 ? (
                                                        <InboxIcon />
                                                    ) : (
                                                        <MailIcon />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t('nav.' + text)}
                                                />
                                            </ListItem>
                                        );
                                },
                            )}
                            <ListItem
                                button
                                component={Link}
                                to="/"
                                onClick={logoutUser}
                            >
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText>登出</ListItemText>
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            )}
            <main className={classes.content}>
                {settings.show && <div className={classes.toolbar} />}
                <Switch>
                    {Routes.map((route) => (
                        <Route exact path={route.path} key={route.path}>
                            <route.component />
                        </Route>
                    ))}
                </Switch>
            </main>
        </div>
    );
};

export default NavigationBar;
