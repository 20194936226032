import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import '../styles/main.css';
import {getPopList} from '@actions/billboardAction';

class StoreList extends Component {

    constructor(props){
        super(props);
        this.state={
            keyword:""
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        // this.props.fetchStore();
    }

    componentWillReceiveProps(nextProps){

        this.props = nextProps;
    }

    onChange(e){
        this.setState( { [e.target.name] : e.target.value } );
    }

    onSubmit(e){
        e.preventDefault();
        if (this.state.keyword===''){
            alert('請輸入關鍵字');
            return;
        }
        // call action
        this.props.searchShopee(this.state);
    }
    
    render() {

        const info = {
            key: "【乾淨衛生】",
            title: "Zekes' 證件 / 口罩掛繩 5 & 10 pack",
            description: "",
            price: "$6.99",
            date: "2020 9月30日",
            host: "Winnie",
            thumb: "./images/square_2020_09_30_13_02-60dad17d.jpg",
            usage: "減少接觸感染"
        };
        const NTD_NORMALIZER = 100000;
        // const arr = (this.props.stores? this.props.stores:[]);
        const arr = [0,1,2,3,4,5,6,7,8,9]
        const Items = arr.map( unit => (

                <div class="vc-recommendation-card" key={arr[unit]}>
                    <div class="placeholder">
                        <a href="#">
                            <img class="cover" data-src="" src="https://www.tommydeals.com//uploads/recommendation/image/image/4912/square_2020_09_30_22_47-cd4b6e7d.jpg" lazy="loaded" />
                        </a>
                    </div>
                    <div class="info-wrapper">
                        <p class="title">{info.key} {info.title} {info.price}</p>
                        <p class="usage">{info.usage}</p>
                        <div class="option-wrapper buttons options">
                            <a class="button is-pink">
                                <span class="text"> 前去購買 </span>
                            </a>
                            <a class="button is-dark">
                                <span class="text"> 了解更多 </span>
                            </a>       
                        </div>
                        
                    </div>
                    
                </div>
                
        ));


        return (
            <div>
                <nav>
                    <div className="head"> 爆款報馬仔 </div>
                </nav>
                <div className="content">
                    {Items}
                </div>

            </div>

        

        )
    }
}

const mapStateToProps = state => { 
    return (
        { 
            stores: state.menuStates.stores
        }
    );
}


export default connect(mapStateToProps, { getPopList })(StoreList)
