import React, { useEffect, Fragment } from 'react';
import { Grid, Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DoughnutChart from '../charts/echarts/Doughnut';
//import DoughnutChart from "../charts/echarts/Doughnut";

import ModifiedAreaChart from './shared/ModifiedAreaChart';

import StatCards from './shared/StatCards';
import TableCard from './shared/TableCard';
import RowCards from './shared/RowCards';
import StatCards2 from './shared/StatCards2';
/*
import UpgradeCard from "./shared/UpgradeCard";
import Campaigns from "./shared/Campaigns";*/
import { withStyles } from '@material-ui/styles';
import '@styles/color.scss';
import { useTranslation } from 'react-i18next';
import { fetchDashboardArticles } from '@reducers/dashboardSlice';

import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
    fetchDashboardArticlesType,
    fetchDashboardAllMembers,
    fetchDashboardAllArticles,
    fetchDashboardTrackings
} from '@reducers/dashboardSlice';
import store from '@src/store';
import { fetchMenuList } from '@reducers/menuListSlice';

const Dashboard1 = () => {
    //state = {};
    const dispatch = useDispatch();

    const allMembers = useSelector((state) => state.dashboardAllMembers);
    useEffect(() => {
        console.log('fetchDashboardAllMembers:' +allMembers.status);
        if (allMembers.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 12);
            console.log(d_end);
            dispatch(
                fetchDashboardAllMembers({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        }
    }, [allMembers, dispatch]);

    const allTrackings = useSelector((state) => state.dashboardAllTrackings);
    useEffect(() => {
        if (allTrackings.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 12);
            console.log(d_end);
            dispatch(
                fetchDashboardTrackings({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        }
    }, [allTrackings, dispatch]);

    const articles = useSelector((state) => state.dashboardArticles);
    useEffect(() => {
        console.log('fetchDashboardArticles:' +articles.status);
        if (articles.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 12);
            console.log(d_end);
            dispatch(
                fetchDashboardArticles({
                    interval: 90,
                    mode: 'months',
                }),
            );
        }
    }, [articles, dispatch]);

    const articlesTypes = useSelector((state) => state.dashboardArticlesType);
    useEffect(() => {
        if (articlesTypes.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 12);
            console.log(d_end);
            dispatch(
                fetchDashboardArticlesType({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        }
    }, [articlesTypes, dispatch]);

    const allArticles = useSelector((state) => state.dashboardAllArticles);
    useEffect(() => {
        if (articlesTypes.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 12);
            console.log(d_end);
            dispatch(
                fetchDashboardAllArticles({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        }
    }, [allArticles, dispatch]);

    const menuStatus = useSelector((state) => state.menuLists.status);
    useEffect(() => {
        console.log(menuStatus);
        if (menuStatus === 'idle') {
            store.dispatch(fetchMenuList());
        }
    }, [menuStatus]);

    const { t, i18n } = useTranslation();
    let arrTrackings = [];
    let arrActiveMembers = [];
    let arrArticles = [];
    let arrReplies = [];
    let arrNotifications = [];
    let arrPurchases = [];
    let arrComments = [];
    let arrIndex = [];
    let arrArticlesType = [];
    let arrAllMembers = [];
    let allMembersIndex = [];
    console.log(articles);
    if (allTrackings.status === 'succeed') {
        for (var ele in allTrackings.entities) {
            
            arrIndex.push(ele);
        }
    }
    console.log('allArticles:');
    console.log(allArticles);
    if (allArticles.status === 'succeed') {
        for (ele in allArticles.entities) {
            arrArticles.push(allArticles.entities[ele].count);
        }
    }
    console.log(allMembers);
    if (allTrackings.status === 'succeed') {
        for (ele in allTrackings.entities) {
            arrTrackings.push(allTrackings.entities[ele].count);
            arrNotifications.push( allTrackings.entities[ele].notifications);
            arrPurchases.push( allTrackings.entities[ele].purchases)
        }
    }
    console.log(arrTrackings);
    if (allMembers.status === 'succeed') {
        for (ele in allMembers.entities) {
            arrAllMembers.push(allMembers.entities[ele].count);
            arrActiveMembers.push(allMembers.entities[ele].activeUsers);
            allMembersIndex.push(ele);
        }
    }
    console.log(arrAllMembers);
    if (articlesTypes.status === 'succeed') {
        let index = 0;
        let accumulated = 0;
        for (ele in articlesTypes.entities) {
            console.log(articlesTypes.entities[ele].value);
            index++;
            if (index >= 6) accumulated += articlesTypes.entities[ele].value;
            else
                arrArticlesType.push({
                    value: articlesTypes.entities[ele].value,
                    name: articlesTypes.entities[ele].name,
                });
        }
        arrArticlesType.push({ value: accumulated, name: '其他' });
    }
    console.log(arrArticlesType);
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6} className="pb-24 pt-7 px-8 bg-chart">
                    <div className="card-title capitalize text-black mb-4 text-black-secondary">
                        {t('dashboard.MEMBERTREND')}
                    </div>
                    <ModifiedAreaChart
                        height="280px"
                        option={{
                            series: [
                                {
                                    name: '活躍人數',
                                    data: arrActiveMembers,
                                    type: 'line',
                                },
                                {
                                    name: '新增社團人數',
                                    data: arrAllMembers,
                                    type: 'bar',
                                },
                            ],
                            color: ['#e91e63 ', '#354EF6'],
                            legend: {
                                // headline location
                                data: ['活躍人數', '新增社團人數'],
                                itemGap: 35,
                                itemHeight: 18,
                                right: '11%',
                                top: 20,
                            },
                            xAxis: {
                                data: allMembersIndex,
                            },
                        }}
                    ></ModifiedAreaChart>
                </Grid>
                <Grid item xs={6} className="pb-24 pt-7 px-8 bg-chart">
                    <div className="card-title capitalize text-black mb-4 text-black-secondary">
                        {t('dashboard.ARTICLETREND')}
                    </div>
                    <ModifiedAreaChart
                        height="280px"
                        option={{
                            series: [
                                {
                                    name: '追蹤數',
                                    data: arrTrackings,
                                    type: 'bar',
                                },
                                {
                                    name: '通知數',
                                    data: arrNotifications,
                                    type: 'line',
                                },
                                {
                                    name: '購買數',
                                    data: arrPurchases,
                                    type: 'line',
                                },
                            ],
                            color: ['#e91e63 ', '#354EF6', '#4CBB17'],
                            legend: {
                                // headline location
                                data: ['追蹤數', '通知數', '購買數'],
                                itemGap: 35,
                                itemHeight: 18,
                                right: '11%',
                                top: 20,
                            },
                            xAxis: {
                                data: arrIndex,
                            },
                        }}
                    ></ModifiedAreaChart>
                </Grid>
            </Grid>
            <div className="analytics m-sm-30 mt--18">
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <StatCards />
                        {/* Top Selling Products */}
                        <StatCards2 />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card className="px-6 py-4 mb-6">
                            <div className="card-title">
                                <big
                                    className="text-green"
                                    style={{ fontSize: '20px' }}
                                >
                                    文章種類
                                </big>
                                <big style={{ fontSize: '12px' }}>
                                    過去三十天
                                </big>
                            </div>
                            <DoughnutChart
                                height="225px"
                                data={arrArticlesType}
                                color={[
                                    '#52D726',
                                    '#FFEC00',
                                    '#FF7300',
                                    '#FF0000',
                                    '#007ED6',
                                    '#7CDDDD',
                                ]}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
};
export default Dashboard1;
//export default withStyles({}, { withTheme: true })(withTranslation()(Dashboard1));
