/* jshint esversion: 8*/
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useSelector, useDispatch } from 'react-redux';
import { queryArticles } from '@actions/articleAction';
import Button from '@material-ui/core/Button';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '../styles/articles.css';
//international
import { useTranslation } from 'react-i18next';
//react-table
import makeData from './makeData';
import MasterDetailTable, { SubRowAsync } from './MasterDetailTable.js';
import styled from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import EnhancedTable from './react-table/EnhancedTable.js';

/*
import { Label } from 'semantic-ui-react';
import {FloatingActionButton} from '@material-ui/button/button/FloatingActionButton';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {pink500} from '@material-ui/core/colors/pink';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
*/

//hank hacking, need to fix
const local_userid = '邱以慈';
const Styles = styled.div`
    padding: 0rem;
    max-width: 100%;
    table {
        border-spacing: 0;
        width: 100%;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));
const ArticlesList = () => {
    const columns = React.useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => null, // No header
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? '👇' : '👉'}
                    </span>
                ),
                // We can override the cell renderer with a SubCell to be used with an expanded row
                SubCell: () => null, // No expander on an expanded row
            },
            {
                Header: 'Name',
                columns: [
                    {
                        Header: 'First Name',
                        // We re-map data using accessor functions for subRows
                        accessor: (d) => d.firstName,
                        // We can render something different for subRows
                        SubCell: (cellProps) => <>🥳 {cellProps.value} 🎉</>,
                    },
                    {
                        Header: 'Last Name',
                        accessor: (d) => d.lastName,
                    },
                ],
            },
            {
                Header: 'Info',
                columns: [
                    {
                        Header: 'Age',
                        accessor: (d) => d.age,
                    },
                    {
                        Header: 'Visits',
                        accessor: (d) => d.visits,
                    },
                    {
                        Header: 'Status',
                        accessor: (d) => d.status,
                    },
                    {
                        Header: 'Profile Progress',
                        accessor: (d) => d.progress,
                    },
                ],
            },
        ],
        [],
    );
    const columns1 = React.useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Age',
                accessor: 'age',
            },
            {
                Header: 'Visits',
                accessor: 'visits',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Profile Progress',
                accessor: 'progress',
            },
        ],
        [],
    );

    const [data, setData] = React.useState(
        React.useMemo(() => makeData(20), []),
    );
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };

    const data1 = React.useMemo(() => makeData(10), []);

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync
                row={row}
                rowProps={rowProps}
                visibleColumns={visibleColumns}
            />
        ),
        [],
    );
    //////////////////////////////////////////
    const classes = useStyles();
    //get the user ID
    const { t, i18n } = useTranslation();
    // get the status
    const postStatus = useSelector((state) => state.articleStates.status);
    const posts = useSelector((state) => state.articleStates);

    const dispatch = useDispatch();

    useEffect(() => {
        if (postStatus === 'idle') {
            console.log('status:' + postStatus);
            dispatch(
                queryArticles({
                    userID: local_userid,
                    beginDate: '01/01/2021',
                }),
            );
        }
    }, [postStatus, dispatch]);

    console.log(posts);
    const CubeRenderer = (props) => {
        const valueCubed = (value) => {
            return value * value * value;
        };

        return (
            <a href={props.value} target="_blank">
                文章{' '}
            </a>
        );
    };

    let renderedPosts;
    /*
    if (postStatus === 'idle') {
        renderedPosts = <div className="loader">Loading...</div>;
    } else if (postStatus === 'succeeded') {
        renderedPosts = posts.articles.data.map(post => (
            <article className="post-excerpt" key={post._id}>
            <h3>{post.title}</h3>
            <p className="post-content">{post.post_id}</p>
            </article>
        ))
    }*/

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState([
        { title: 'Toyota', post_id: 'Celica', time: 35000 },
        { title: 'Ford', post_id: 'Mondeo', time: 32000 },
        { title: 'Porsche', post_id: 'Boxter', time: 72000 },
    ]);

    const getRowHeight = (params) => {
        console.log(params.data.rowHeight);
        return params.data.rowHeight;
    };

    // header name definition
    const headerTitle = t('articles.title');
    const headerPostId = t('articles.post_id');
    const headerMessage = t('articles.message');
    const headerTime = t('articles.time');
    const memberTitle = t('articles.memberTitle');
    const memberInteractions = t('articles.interactions');
    let totalcount = posts.articles.count;
    if (!totalcount) totalcount = t('main.loading');
    let showingUser = posts.articles.query;
    return (
        <div>
            <Button startIcon={<AccountCircle />}>{showingUser}</Button>
            <span className="test_category">
                {memberInteractions + ':' + totalcount}
            </span>

            <div
                className="ag-theme-balham"
                style={{ height: 900, width: '100%' }}
            >
                <AgGridReact
                    id="myGridId"
                    headerHeight="60"
                    defaultColDef={{
                        width: 150,
                        wrapText: true,
                        autoHeight: true,
                        editable: true,
                        floatingFilter: true,
                        resizable: true,
                    }}
                    frameworkComponents={{
                        cubeRenderer: CubeRenderer,
                    }}
                    getRowHeight={getRowHeight}
                    rowData={posts.articles.data}
                >
                    <AgGridColumn
                        headerName={headerPostId}
                        field="post_id"
                        cellRenderer="cubeRenderer"
                        width={120}
                    ></AgGridColumn>
                    <AgGridColumn
                        headerName={headerTitle}
                        field="title"
                        sortable={true}
                        filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                        headerName={headerMessage}
                        field="message"
                        sortable={true}
                        filter={true}
                        width={1000}
                    ></AgGridColumn>
                    <AgGridColumn
                        headerName={headerTime}
                        field="time"
                        sortable={true}
                        filter={true}
                    ></AgGridColumn>
                </AgGridReact>
            </div>
            <div>
                <CssBaseline />
                <EnhancedTable
                    columns={columns1}
                    data={data}
                    setData={setData}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                />
            </div>
        </div>
    );
};

export default ArticlesList;
