/* jshint esversion: 6 */
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { matchRoutes } from "react-router-config";
import AppContext from '../../AppContext';
import {setLayoutSettings, setDefaultSettings} from '@actions/LayoutAction';
import { isEqual, merge } from "lodash";
import NavigationBar from './NavigationBar';
import { LuxiLayoutSettings } from '../../LuxiLayouts/settings';
//import store from '../store';

const Layout1 = props => {
    const appContext = useContext(AppContext);
    const settings = useSelector(state => state.LayoutSetting.settings);
    const dispatch = useDispatch();
    console.log(settings);
    const updateSettingsFromRouter = () => {
      console.log('updateSettingsFromRouter');
      const { routes } = appContext;
      const matched = matchRoutes(routes, props.location.pathname)[0];
      console.log(matched);
      if (matched && matched.route.settings) {
        // ROUTE HAS SETTINGS
        const updatedSettings = merge({}, settings, matched.route.settings);
        console.log(updatedSettings);
        if (!isEqual(settings, updatedSettings)) {
          dispatch(setLayoutSettings(updatedSettings));
          console.log('Route has settings');
        }
        
      }else if (matched && !isEqual(settings, LuxiLayoutSettings)) {
        dispatch(setLayoutSettings(LuxiLayoutSettings));
        console.log('reset settings', LuxiLayoutSettings);
      } 
    };

    useEffect(() => {
      updateSettingsFromRouter();
    }, [props.location]);
    
    console.log('layout1');
    console.log(props);
    return (
        <div>
            {<NavigationBar />}
        </div>
    );
};

export default withRouter(Layout1);
