import React, {useEffect} from 'react';
import SimpleListDnD from './SimpleListDnD';
import { useSelector, useDispatch } from 'react-redux';
import { selectMenuLists, saveNewMenu } from '@reducers/menuListSlice';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import SimpleCard from '@components/SimpleCard';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//international
import { useTranslation } from 'react-i18next';
import store from '@src/store';
import {fetchMenuList} from '@reducers/menuListSlice';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(6),
      minWidth: 120,
      
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    TextFiled: {
        marginRight: theme.spacing(6),
        marginLeft: theme.spacing(3),
    },
    ButtonFiled: {
        marginTop: theme.spacing(2),
    },
  }));

const MenuSetting = () => {
    const menulist = useSelector( selectMenuLists);
    const classes = useStyles();
    const [type, setType] = React.useState('');
    const [listName, setListName] = React.useState('');
    const dispatch = useDispatch();

    const addMenuList = () => {

    };
    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleAddTag = (event) => {
        let filter = menulist.filter( (menu, index)=> {return menu.type==type;});
        console.log(filter.length);
        dispatch(saveNewMenu({value:listName,order:filter.length,type:type}));
        setListName('');
    }

    const menuStatus = useSelector((state) => state.menuLists.status);
    useEffect(() => {
        console.log(menuStatus);
        if (menuStatus === 'idle') {
            store.dispatch(fetchMenuList());
        }
    }, [menuStatus]);

    const { t, i18n } = useTranslation();

    return (
        <div className="m-sm-30">
            
            <SimpleCard className='d-flex flex-column'>
                <div>
            <TextField className={classes.TextFiled} id="outlined-basic" label="標籤" variant="outlined" value={listName} onChange={(e) => { setListName(e.target.value); }}/>
            <Button className={classes.ButtonFiled} variant="contained" color="primary" onClick={handleAddTag}>
                增加標籤
            </Button>
            </div>
            <FormControl className={classes.formControl}>
            
            <InputLabel id="demo-simple-select-helper-label">{t('menusetting.type')}</InputLabel>
            
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={type}
                onChange={handleChange}
            >
                <MenuItem value='article_tag'>{t('menusetting.article')}</MenuItem>
                <MenuItem value='member_tag'>{t('menusetting.member')}</MenuItem>
            </Select>
            </FormControl>
                <SimpleListDnD type={type}/>
            </SimpleCard>
        </div>
    );
};

export default MenuSetting;
