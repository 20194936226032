import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { queryArticles } from '@actions/articleAction';
import EnhancedTable, { SubRowAsync } from './react-table/EnhancedTable';
import makeData from './makeData';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
//international
import { useTranslation } from 'react-i18next';
import { fetchMenuList } from '@reducers/menuListSlice';
import store from '../store';
import Moment from 'react-moment';
import 'moment-timezone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import twLocale from 'date-fns/locale/zh-CN';
import styled from 'styled-components';
import moment from 'moment';
import FindReplaceTwoToneIcon from '@material-ui/icons/FindReplaceTwoTone';

const localeMap = {
    en: enLocale,
    tw: twLocale,
};

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`;

//const uuidv4 = require('uuid/v4');
const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

function MyCell({
    value,
    columnProps: {
        rest: { someFunc },
    },
}) {
    return (
        <a href="#" onClick={someFunc}>
            {value}
        </a>
    );
}
function isValidDate(d) {
    //return d instanceof Date && !isNaN(d);
    return moment(d).isValid();
}

//store.dispatch(fetchMenuList());
const MemberArticles = ({ show_total }) => {
    let local_userid = JSON.parse(sessionStorage.getItem('usersession')).name;

    const classes = useStyles();
    // The first commit of Material-UI
    const [endDate, setEndDate] = React.useState(new Date());

    let d_start = new Date();
    d_start.setMonth(d_start.getMonth() - 6);
    const [total, settotal] = React.useState(show_total);
    const [startDate, setstartDate] = React.useState(d_start);
    const handleDateChange = (date, value) => {
        setEndDate(new Date(moment(value).format('MM/DD/YYYY')));
    };
    const handleStartDateChange = (date, value) => {
        setstartDate(new Date(moment(value).format('MM/DD/YYYY')));
    };

    const handleSearch = () => {
        dispatch(
            queryArticles({
                //userID: local_userid,
                beginDate: moment(startDate).format('MM/DD/YYYY'),
                endDate: moment(endDate).format('MM/DD/YYYY'),
            }),
        );
    };

    const menuStatus = useSelector((state) => state.menuLists.status);
    useEffect(() => {
        console.log(menuStatus);
        if (menuStatus === 'idle') {
            store.dispatch(fetchMenuList());
        }
    }, [menuStatus]);

    // get the status
    const postStatus = useSelector((state) => state.articleStates.status);
    const posts = useSelector((state) => state.articleStates);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(postStatus);
        console.log(total);
        if (postStatus === 'idle' || total === undefined) {
            dispatch(
                queryArticles({
                    //userID: local_userid,
                    beginDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                }),
            );
            settotal(true);
            //store.dispatch(fetchMenuList());
        }
    }, [postStatus, startDate, endDate, dispatch]);

    useEffect(() => {
        console.log(endDate);
        console.log(moment(endDate).format('MM/DD/YYYY'));
        console.log(postStatus);
        if (postStatus === 'idle' || postStatus === 'succeed') {
            dispatch(
                queryArticles({
                    //userID: local_userid,
                    beginDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                }),
            );
        }
    }, [startDate, endDate, dispatch]);

    console.log(postStatus);
    console.log(startDate);
    let articles;
    if (postStatus !== 'succeeded') articles = [];
    else articles = posts.articles.data;

    const { t, i18n } = useTranslation();
    // header name definition
    const headerTitle = t('articles.title');
    const headerPostId = t('articles.post_id');
    const headerMessage = t('articles.message');
    const headerTime = t('articles.time');
    const headerTags = t('articles.tags');
    const headerInterations = t('articles.interactions');
    const memberInteractions = t('articles.interactions');
    const memberTitle = t('articles.memberTitle');
    const lblstartDate = t('articles.startDate');
    const lblendDate = t('articles.endDate');
    const language = t('articles.language');
    const lblCancel = t('articles.cancelbutton');
    const lblOk = t('articles.okbutton');

    let totalcount = posts.articles.count;

    if (!totalcount && postStatus !== 'succeeded')
        totalcount = t('main.loading');
    let showingUser = posts.articles.query;
    console.log('showingUser:' + showingUser);
    if (total) showingUser = t('articles.all_articles');
    const columns = React.useMemo(
        () => [
            {
                Header: headerTime,
                accessor: 'postisotime',
                Cell: ({ cell: { value }, row: { original } }) => {
                    if (isValidDate(value))
                        return (
                            <Moment
                                className="tabledate_header"
                                add={{ hours: 4 }}
                                format="YYYY/MM/DD hh:mm"
                                tz="Asia/Taipei"
                            >
                                {value}
                            </Moment>
                        );
                    else return <></>;
                },
            },
            {
                Header: headerTitle,
                accessor: 'title',
            },
            {
                Header: headerMessage,
                accessor: 'message',
                Cell: ({ cell: { value }, row: { original } }) => {
                    let message = value.toString().substring(0, 200) + '...';
                    if (original.photos[0])
                        return (
                            <>
                                <div className="table_message">
                                    <a
                                        href={`${original.post_id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {message}
                                    </a>
                                </div>
                                <div className="table_message">
                                    <img
                                        className="thumbnail"
                                        src={original.photos[0]}
                                        width={320}
                                        alt=""
                                    />
                                </div>
                            </>
                        );
                    else
                        return (
                            <div className="table_message">
                                <a
                                    href={`${original.post_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {message}
                                </a>
                            </div>
                        );
                },
            },
            {
                Header: headerTags,
                accessor: 'articleTags',
                Cell: ({ cell: { value }, row: { original } }) => {
                    if (value)
                        return (
                            <div className="table_tag">
                                {value.map((tag) => (
                                    <span
                                        className="badge badge-danger"
                                        key={tag + original._id + uuidv4()}
                                    >
                                        {tag}
                                    </span>
                                ))}
                                <br />
                            </div>
                        );
                    else return <></>;
                },
            },
            {
                Header: headerInterations,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.interactions.length;
                },
                Cell: ({ cell: { value }, row: { original } }) => {
                    return (
                        <>
                            <div className="stripbox">
                                <span
                                    className="spanbox"
                                    style={{
                                        display: 'block',
                                        fontSize: '14px',
                                    }}
                                >
                                    {'互動數:'}
                                    {value}
                                </span>
                                <span
                                    className="spanbox"
                                    style={{ display: 'block' }}
                                >
                                    <hr
                                        align="left"
                                        width={`${value}`}
                                        className="colorstrip"
                                    />
                                </span>
                            </div>
                            <div className="stripbox">
                                <span
                                    className="spanbox"
                                    style={{
                                        display: 'block',
                                        fontSize: '14px',
                                    }}
                                >
                                    {'留言數:'}
                                    {original.comments.length}
                                </span>
                                <span style={{ display: 'block' }}>
                                    <hr
                                        align="left"
                                        width={`${original.comments.length}`}
                                        className="commentcolorstrip"
                                    />
                                </span>
                            </div>
                        </>
                    );
                },
            },
        ],
        [],
    );
    console.log(articles);
    //data = articles;
    //const [data, setData] = React.useState([]);
    const [data, setData] = React.useState(
        React.useMemo(() => makeData(20), []),
    );
    console.log(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync
                row={row}
                rowProps={rowProps}
                visibleColumns={visibleColumns}
            />
        ),
        [],
    );

    return (
        <div>
            <SearchBox>
                <div className="d-flex">
                    <Button
                        className="test_category"
                        startIcon={<AccountCircle />}
                    >
                        {showingUser}
                    </Button>
                    <span className="test_category">
                        {memberInteractions + ':' + totalcount}
                    </span>
                </div>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[language]}
                >
                    <Grid container className="date_box">
                        <KeyboardDatePicker
                            className="picker_box"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={lblstartDate}
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <KeyboardDatePicker
                            className="picker_box"
                            margin="normal"
                            id="date-picker-dialog"
                            label={lblendDate}
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <Button
                            onClick={handleSearch}
                            className="test_category"
                            startIcon={
                                <FindReplaceTwoToneIcon
                                    style={{ fontSize: 32 }}
                                />
                            }
                        ></Button>
                    </Grid>
                </MuiPickersUtilsProvider>
            </SearchBox>
            <CssBaseline />

            <EnhancedTable
                columns={columns}
                data={articles}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                renderRowSubComponent={renderRowSubComponent}
                userID={showingUser}
            />
        </div>
    );
};

export default MemberArticles;
