import {GET_POPLIST} from './types';
const querystring = require('querystring');


const getPopList = (searchParams) => (dispatch) => {

    let popList = {};

    dispatch(
        {
            type: GET_POPLIST,
            payload: popList
        }
    )
}

export {getPopList}
