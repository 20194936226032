import React from "react";
import {default as Layout1} from './Layout1/Layout1';
import {setDefaultSettings} from '@actions/LayoutAction';
import { useDispatch } from "react-redux";

const LuxiLayouts = () => {
    const dispatch = useDispatch();
    dispatch(setDefaultSettings());
    return <Layout1/>;
}
export default LuxiLayouts;