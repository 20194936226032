import { SET_LAYOUT_SETTINGS, SET_DEFAULT_SETTINGS } from './types';

export const setLayoutSettings = data => dispatch => {
    console.log('setLayoutSettings');
  dispatch({
    type: SET_LAYOUT_SETTINGS,
    data: data
  });
};

export const setDefaultSettings = data => dispatch => {
    console.log('setDefaultSettings');
    dispatch({
      type: SET_DEFAULT_SETTINGS,
      data: data
    });
  };