import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { queryShopperUsers } from '@actions/shopperUserAction';
import EnhancedTable, { SubRowAsync } from './react-table/EnhancedTable';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import visuallyHidden from '@mui/utils/visuallyHidden';

import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';

import makeData from './makeData';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
//international
import { useTranslation } from 'react-i18next';
import { fetchMenuList } from '@reducers/menuListSlice';
import store from '../store';
import Moment from 'react-moment';
import 'moment-timezone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import twLocale from 'date-fns/locale/zh-CN';
import styled from 'styled-components';
import moment from 'moment';
import FindReplaceTwoToneIcon from '@material-ui/icons/FindReplaceTwoTone';

const localeMap = {
    en: enLocale,
    tw: twLocale,
};

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`;

//const uuidv4 = require('uuid/v4');
const { v4: uuidV4 } = require('uuid');
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

function MyCell({
    value,
    columnProps: {
        rest: { someFunc },
    },
}) {
    return (
        <a href="#" onClick={someFunc}>
            {value}
        </a>
    );
}
function isValidDate(d) {
    //return d instanceof Date && !isNaN(d);
    return moment(d).isValid();
}

//store.dispatch(fetchMenuList());
const MyshopperUser = ({ show_total }) => {
    let local_userid = JSON.parse(sessionStorage.getItem('usersession')).name;

    // The first commit of Material-UI
    const [endDate, setEndDate] = React.useState(new Date());

    let d_start = new Date();
    d_start.setMonth(d_start.getMonth() - 1);
    const [total, settotal] = React.useState(show_total);
    const [startDate, setstartDate] = React.useState(d_start);
    const handleDateChange = (date, value) => {
        setEndDate(new Date(moment(value).format('MM/DD/YYYY')));
    };
    const handleStartDateChange = (date, value) => {
        setstartDate(new Date(moment(value).format('MM/DD/YYYY')));
    };

    const handleSearch = () => {
        dispatch(
            queryShopperUsers({
                //userID: local_userid,
                beginDate: moment(startDate).format('MM/DD/YYYY'),
                endDate: moment(endDate).format('MM/DD/YYYY'),
            }),
        );
    };

    const menuStatus = useSelector((state) => state.menuLists.status);
    useEffect(() => {
        console.log(menuStatus);
        if (menuStatus === 'idle') {
            store.dispatch(fetchMenuList());
        }
    }, [menuStatus]);

    // get the status
    const postStatus = useSelector((state) => state.shopperuserStatus.status);
    const posts = useSelector((state) => state.shopperuserStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(postStatus);
        console.log(total);
        if (postStatus === 'idle' || total === undefined) {
            dispatch(
                queryShopperUsers({
                    //userID: local_userid,
                    beginDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                }),
            );
            settotal(true);
            //store.dispatch(fetchMenuList());
        }
    }, [postStatus, startDate, endDate, dispatch]);

    console.log(posts);
    console.log(startDate);
    let products;
    if (postStatus !== 'succeeded') products = [];
    else products = posts.products.data;

    const { t, i18n } = useTranslation();
    // header name definition
    const headerTitle = t('tracking.title');
    const headerID = t('tracking.userid');
    const headerMessage = t('tracking.message');
    const headerTags = t('tracking.base_price');
    const headerPurchases = t('tracking.purchases');
    const headerPrice = t('tracking.target_price');
    const headerName = t('tracking.user_name');
    const headerPhoto = t('tracking.user_photo');
    const headerProducts = t('tracking.products');
    const memberInteractions = t('tracking.interactions');
    const headerJoin = t('tracking.user_join');
    const lblstartDate = t('tracking.startDate');
    const lblendDate = t('tracking.endDate');
    const language = t('tracking.language');
    const lblCancel = t('tracking.cancelbutton');
    const lblOk = t('tracking.okbutton');

    let totalcount = posts.products.count;

    if (!totalcount && postStatus !== 'succeeded')
        totalcount = t('main.loading');
    let showingUser = posts.products.query;
    console.log('showingUser:' + showingUser);
    if (total) showingUser = t('articles.all_articles');

    console.log(products);
    //const [data, setData] = React.useState([]);
    const [data, setData] = React.useState(
        React.useMemo(() => makeData(20), []),
    );
    console.log(data);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('purchase');
    const handleRequestSort = (property) => (event) => {
        console.log(products);
        //products = products.sort((a, b) => a[property].length > b[property].length ? (order === "desc" ? -1 : 1) : (order === "desc" ? 1 : -1));
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setOrderBy(property);
        console.log(property);
    };

    const handleCreateSort = (property) => (event) => {
        console.log(products);
        //products = products.sort((a, b) => a['createAt'] > b['createAt'] ? (order === "desc" ? -1 : 1) : (order === "desc" ? 1 : -1));
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setOrderBy(property);
        console.log(property);
    };

    const handlehitcntSort = (property) => (event) => {
        console.log(products);
        //products = products.sort((a, b) => getHitCnt(a) > getHitCnt(b) ? (order === "desc" ? -1 : 1) : (order === "desc" ? 1 : -1));
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync
                row={row}
                rowProps={rowProps}
                visibleColumns={visibleColumns}
            />
        ),
        [],
    );

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function getHitCnt(row) {
        let hit_cnt = 0;
        for (let i = 0; i < row.purchase.length; i++) {
            hit_cnt += row.purchase[i].length;
        }
        return hit_cnt;
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        let hit_cnt = 0;
        let track_cnt = 0;
        let hit_record = [];
        for (let i = 0; i < row.purchase.length; i++) {
            hit_cnt += row.purchase[i].length;
            //for (let j = 0; j < row.purchase[i].length; j++) {
            hit_record.push({
                _id: row.track_id[i] + i + 'h',
                datetime: row.purchase[i].datetime,
                product_url: row.purchase[i].product_url,
                snapshot: row.snapshot[i],
                title: row.title[i],
            });
            //}
        }
        let track_record = [];
        for (let i = 0; i < row.track_id.length; i++) {
            //track_cnt = row.track_id.length;
            track_record.push({
                _id: row.track_id[i] + i + 't',
            });
        }
        return (
            <React.Fragment>
                <TableRow key={row._id}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell align="center">{row.user_name}</TableCell>
                    <TableCell align="center">
                        <TableCell component="tr" scope="row" align="center">
                            <div className="table_message">
                                <img src={row.user_pic} width={160} alt="" />
                            </div>
                        </TableCell>
                    </TableCell>

                    <TableCell align="center">
                        {moment(row.createAt[0]).format('YYYY/MM/DD HH:mm:ss')}
                    </TableCell>
                    <TableCell align="center">{row.track_id.length}</TableCell>
                    <TableCell align="center">{row.purchase.length}</TableCell>
                    <TableCell align="center"> </TableCell>
                </TableRow>
                <TableRow key={row._id + '1'}>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    追蹤記錄
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Track Link</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {track_record.map((historyRow) => (
                                            <TableRow key={historyRow._id}>
                                                <TableCell>
                                                    <a
                                                        href={historyRow._id}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {historyRow._id}
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    購買記錄
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>產品</TableCell>
                                            <TableCell>照片</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hit_record.map((historyRow) => (
                                            <TableRow key={historyRow._id}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Moment
                                                        className="tabledate_header"
                                                        add={{ hours: 4 }}
                                                        format="YYYY/MM/DD hh:mm"
                                                        tz="Asia/Taipei"
                                                    >
                                                        {historyRow.datetime}
                                                    </Moment>
                                                </TableCell>
                                                <TableCell>
                                                    <a
                                                        href={
                                                            historyRow.product_url
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {historyRow.title}
                                                    </a>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    <div className="table_message">
                                                        <img
                                                            className="thumbnail"
                                                            src={
                                                                historyRow.snapshot
                                                            }
                                                            width={160}
                                                            alt=""
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <div>
            <SearchBox>
                <div className="d-flex">
                    <Button
                        className="test_category"
                        startIcon={<AccountCircle />}
                    >
                        {showingUser}
                    </Button>
                    <span className="test_category">
                        {memberInteractions + ':' + totalcount}
                    </span>
                </div>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[language]}
                >
                    <Grid container className="date_box">
                        <KeyboardDatePicker
                            className="picker_box"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={lblstartDate}
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <KeyboardDatePicker
                            className="picker_box"
                            margin="normal"
                            id="date-picker-dialog"
                            label={lblendDate}
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            cancelLabel={React.createElement(
                                'span',
                                null,
                                lblCancel,
                            )}
                            okLabel={React.createElement('span', null, lblOk)}
                        />
                        <br></br>
                        <Button
                            onClick={handleSearch}
                            className="test_category"
                            startIcon={
                                <FindReplaceTwoToneIcon
                                    style={{ fontSize: 32 }}
                                />
                            }
                        ></Button>
                    </Grid>
                </MuiPickersUtilsProvider>
            </SearchBox>
            <CssBaseline />
            <MaUTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">{headerName}</TableCell>
                        <TableCell align="center">{headerPhoto}</TableCell>
                        <TableCell
                            align="center"
                            onClick={handleCreateSort('createAt')}
                        >
                            <TableSortLabel
                                active={orderBy === 'createAt'}
                                direction={
                                    orderBy === 'createAt' ? order : 'asc'
                                }
                            >
                                {headerJoin}
                                {orderBy === 'createAt' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="center"
                            onClick={handleRequestSort('title')}
                        >
                            <TableSortLabel
                                active={orderBy === 'title'}
                                direction={orderBy === 'title' ? order : 'asc'}
                            >
                                {headerProducts}
                                {orderBy === 'title' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="center"
                            onClick={handlehitcntSort('hitcnt')}
                        >
                            <TableSortLabel
                                active={orderBy === 'hitcnt'}
                                direction={orderBy === 'hitcnt' ? order : 'asc'}
                            >
                                {headerPurchases}
                                {orderBy === 'hitcnt' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">{}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                </TableBody>
            </MaUTable>
            <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default MyshopperUser;
