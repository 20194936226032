import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from '@reducers'
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const initialState = {};
const middleWare = [thunk];

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') { // check for action type 
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;