import React, { useEffect } from 'react';
import { Grid, Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchDashboardMembers,
    fetchDashboardInteractions,
} from '@reducers/dashboardSlice';
import { format } from 'date-fns';
import { Translation, useTranslation } from 'react-i18next';

const styles = (theme) => ({
    icon: {
        fontSize: '44px',
        opacity: 0.6,
        color: 'rgba(52, 49, 255, 0.8)',
    },
});

const StatCards = ({ classes }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const members = useSelector((state) => state.dashboardMembers);
    console.log('fetchDashboardMembers:' +members.ids.length);
    useEffect(() => {
        console.log('fetchDashboardMembers:' + members.status);
        if (members.status === 'idle') {
            let d = new Date();
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 1);
            console.log('dispatch fetchDashboardMembers');
            dispatch(
                fetchDashboardMembers({ begin: format(d_end, 'yyyy/MM/dd') }),
            );
        }
    }, [members, dispatch]);

    const interactions = useSelector((state) => state.dashboardInteractions);
    useEffect(() => {
        if (interactions.status === 'idle') {
            let d = new Date();
            console.log(format(d, 'yyyy/MM/dd'));
            let d_end = new Date();
            d_end.setMonth(d_end.getMonth() - 2);
            console.log('fetchDashboardInteractions');
            dispatch(
                fetchDashboardInteractions({
                    begin: format(d_end, 'yyyy/MM/dd'),
                    end: format(d, 'yyyy/MM/dd'),
                }),
            );
        } else if (interactions.status === 'succeed') {
            console.log('inter:');
            console.log(interactions.entities.count);
        }
    }, [interactions, dispatch]);
    return (
        <Grid container spacing={3} className="mb-6">
            <Grid item xs={12} md={6}>
                <Card
                    className="play-card p-sm-24 bg-paper d-flex flex-row"
                    elevation={6}
                    style={{height: '130px'}}
                >
                    <div className="d-flex flex-grow-1 items-center">
                        <Icon className={classes.icon}>group</Icon>
                        <div className="ml-3">
                            <big
                                className="text-green"
                                style={{ fontSize: '20px' }}
                            >
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i>
                                            {t('dashboard.statcard.newmembers')}
                                        </i>
                                    )}
                                </Translation>
                            </big>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                {members.ids.length}
                            </h6>
                        </div>
                    </div>
                    <Tooltip
                        title={t('dashboard.statcard.viewdetails')}
                        placement="top"
                    >
                        <IconButton>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card
                    className="play-card p-sm-24 bg-paper d-flex flex-row"
                    elevation={6}
                    style={{height: '130px'}}
                >
                    <div className="d-flex flex-grow-1 items-center">
                        <Icon className={classes.icon}>attach_money</Icon>
                        <div className="ml-3">
                            <big
                                className="text-green"
                                style={{ fontSize: '20px' }}
                            >
                                <Translation>
                                    {(t, { i18n }) => (
                                        <i>
                                            {t(
                                                'dashboard.statcard.interactions',
                                            )}
                                        </i>
                                    )}
                                </Translation>
                            </big>
                            <br></br>
                            <div className="d-flex ">
                                <small className="m-0 mt-1 text-muted">
                                    <Translation>
                                        {(t, { i18n }) => (
                                            <i>
                                                {t(
                                                    'dashboard.statcard.commentsCnt',
                                                )}
                                            </i>
                                        )}
                                    </Translation>
                                </small>
                                <h6 className="m-0 mt-1 text-primary font-medium">
                                    {interactions.status === 'succeed'
                                        ? (interactions.ids.length!==0 ? interactions.entities[0].commentsCnt :
                                            '0' )
                                        : 'loading'}
                                </h6>
                                <small className="m-0 mt-1 text-muted">
                                    <Translation>
                                        {(t, { i18n }) => (
                                            <i>
                                                {t(
                                                    'dashboard.statcard.interactionsCnt',
                                                )}
                                            </i>
                                        )}
                                    </Translation>
                                </small>
                                <h6 className="m-0 mt-1 text-primary font-medium">
                                    {interactions.status === 'succeed'
                                        ? ( interactions.ids.length!==0 ? interactions.entities[0].interactionsCnt :
                                            '0')
                                        : 'loading'}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <Tooltip
                        title={t('dashboard.statcard.viewdetails')}
                        placement="top"
                    >
                        <IconButton>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles, { withTheme: true })(StatCards);
