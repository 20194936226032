import React from 'react';
import MenuSetting from './MenuSetting';
import { authRoles } from '../../auth/authRoles';

const MenuSettingRoute = [
    {
        path: '/MenuDesign',
        component: MenuSetting,
        sidebarName: 'MenuSetting',
        exact: true,
        auth: authRoles.admin,
    },
];

export default MenuSettingRoute;
