/* jshint esversion:6 */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { merge } from 'lodash';
import '@styles/color.scss';
const defaultOption = {
    grid: {
        top: 50,
        left: 60,
        right: 50,
        bottom: 50,
        tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function(params) {
                  return params.value.replace("\n", "");
                }
              }
            }
          }
    },
    color: ["#e91e63 ", "#354EF6"],
    legend: {},
    tooltip: {
        show: true,
        trigger: 'axis',

        axisPointer: {
            type: 'cross',
            lineStyle: {
                opacity: 0,
            },
        },
        crossStyle: {
            color: '#eee',
        },
    },
    xAxis: {
        show: true,
        type: 'category',
        axisLabel: {
            interval: 0,
            rotate: 55,
            textStyle: {
                baseline: 'top',
                color: '#333',
                fontSize: 10,
                fontWeight: 'bold',
            },
        },
        axisLine: { lineStyle: { color: "#aaa" }, show: true },
        axisTick: {
            show: false,
        },
        splitLine: { show: false },
    },
    yAxis: [
        {
            axisLabel: {
                textStyle: { fontSize: 12 },
            },
            axisLine: { show: false },
            axisTick: { show: false },
            name: '個數',
            splitLine: {
                lineStyle: {
                    type: 'dotted',
                },
            },
            type: 'value',
        },
    ],
};

const ModifiedAreaChart = ({ height, option }) => {
    return (
        <ReactEcharts
            style={{ height: height, left: 50, top: 20, width: 'auto'}}
            option={merge({}, defaultOption, option)}
            opts={{ renderer: "svg" }}
        />
        
    );
};

export default ModifiedAreaChart;
