import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateArticleTag } from '@actions/articleAction';
import { updateMemberTag } from '@actions/memberActions';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Loyalty';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import CreatableSelect from 'react-select/creatable';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { selectMenuLists, saveNewMenu } from '@reducers/menuListSlice';
import { useTranslation } from 'react-i18next';
import store from '../../store';

const filter = createFilterOptions();
const top100Films = ['旅遊', '心靈'];

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const initialUser = {
    firstName: '',
    lastName: '',
    age: 0,
    visits: 0,
    status: 'single',
    progress: 0,
    subRows: undefined,
};

const UpdateTagDialog = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [user, setUser] = useState(initialUser);
    const { updateTagHandler, selectedRow, originalTag, userID, mode } = props;
    const [open, setOpen] = React.useState(false);
    const AllList = useSelector(selectMenuLists);
    const FilteredAllList = AllList.filter((menu) => menu.type === mode);
    const menulist = FilteredAllList.map((menu) => menu.value);
    console.log(menulist);
    const [switchState, setSwitchState] = React.useState({
        addMultiple: false,
    });
    const [newTag, setNewTag] = useState([]);

    let currentTags = originalTag();
    console.log(currentTags);
    const handleSwitchChange = (name) => (event) => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const resetSwitch = () => {
        setSwitchState({ addMultiple: false });
    };

    const handleClickOpen = () => {
        currentTags = originalTag();
        console.log('handleClickOpen');
        console.log(currentTags);
        console.log('handleClickOpen');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetSwitch();
    };

    const getDefaultValue = () => {
        return Array.from(currentTags);
    };

    const handleAdd = (event) => {
        let rows = '',
            tags = '';
        Object.keys(selectedRow).map((x) => (rows = rows + x + ';'));
        rows = rows.replace(/;\s*$/, '');
        currentTags.forEach((element) => {
            let addedstring = element;
            if (typeof element != 'string') {
                addedstring = element.inputValue;
                //trigger to add new tag value
                dispatch(
                    saveNewMenu({
                        value: element.inputValue,
                        order: 0,
                        type: mode,
                    }),
                );
            }
            tags = tags + addedstring + ';';
        });
        tags = tags.replace(/;\s*$/, '');
        console.log(mode);

        if (mode === 'article_tag') {
            dispatch(
                updateArticleTag({
                    userID: userID,
                    rows: rows,
                    tags: tags,
                }),
            );
        } else if (mode === 'member_tag') {
            dispatch(
                updateMemberTag({
                    userID: userID,
                    rows: rows,
                    tags: tags,
                }),
            );
            console.log('update member tags');
        } else {
            console.log('unknown mode !');
        }
        setOpen(false);
        updateTagHandler(user);
    };

    const handleChange = (event, value) => {
        console.log(currentTags);
        currentTags = value;
        console.log(value);
    };
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Tooltip title="Update">
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <UpdateIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth="true"
            >
                <DialogTitle id="form-dialog-title" >更新標籤</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('updatetag.choosetag')}
                    </DialogContentText>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={menulist}
                        getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === 'string') {
                                console.log('add:' + option);
                                return option;
                            }
                            if (option.inputValue) {
                                console.log('addnew:' + option.inputValue);
                                return option.inputValue;
                            }
                            return option.title;
                        }}
                        defaultValue={getDefaultValue}
                        filterSelectedOptions
                        onChange={handleChange}
                        noOptionsText={'add'}
                        onInputChange={async (event, value) => {
                            console.log(event);
                            console.log(value);
                            console.log('onInputChange has been fired');
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    title: `新增 "${params.inputValue}"`,
                                });
                            }
                            console.log(filtered);
                            return filtered;
                        }}
                        renderOption={(option) =>
                            typeof option === 'string' ? option : option.title
                        }
                        renderInput={(params) => (
                            <TextField
                                style = {{width: 300}}
                                {...params}
                                variant="outlined"
                                label="請選擇標籤"
                                placeholder="標籤"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        取消
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        更新
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

UpdateTagDialog.propTypes = {
    updateTagHandler: PropTypes.func.isRequired,
    selectedRow: PropTypes.object.isRequired,
    originalTag: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
};

export default UpdateTagDialog;
