import React from 'react';

import UpdateTagDialog from './UpdateTagDialog.js';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Loyalty';
import GlobalFilter from './GlobalFilter.js';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        numSelected,
        selectedRowIds,
        originalTag,
        userID,
        addUserHandler,
        deleteUserHandler,
        updateTagHandler,
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
    } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                >
                    
                </Typography>
            )}

            {numSelected > 0 ? (
                <UpdateTagDialog
                    updateTagHandler={updateTagHandler}
                    selectedRow={selectedRowIds}
                    originalTag={originalTag}
                    userID={userID}
                    mode="article_tag"
                />
            ) : (
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            )}
        </Toolbar>
    );
};

TableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selectedRowIds: PropTypes.object.isRequired,
    deleteUserHandler: PropTypes.func.isRequired,
    updateTagHandler: PropTypes.func.isRequired,
    setGlobalFilter: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.array.isRequired,
};

export default TableToolbar;
